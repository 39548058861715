import { Position, Toaster, Intent } from '@blueprintjs/core'

const AppToaster = Toaster.create({
    className: '',
    position: Position.BOTTOM,
})

export default function Toast(intent, message) {
    let _intent = null
    let _icon = null
    switch (intent) {
        case 'success':
            _intent = Intent.SUCCESS
            _icon = 'check-sign'
            break
        case 'danger':
            _intent = Intent.DANGER
            _icon = 'danger-sign'
            break
        case 'warning':
            _intent = Intent.WARNING
            _icon = 'warning-sign'
            break
        case 'primary':
            _intent = Intent.PRIMARY
            _icon = ''
            break
        default:
            break
    }
    AppToaster.show({ icon: _icon, intent: _intent, message: message })
}
