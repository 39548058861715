import React from 'react'
import styled from 'styled-components'
import { Row, Col, Container } from 'reactstrap'
import { GetCategory, UpdateCategory } from '../../API/Excursions'
import Toast from '../../Components/Toast'
import { Spinner, Switch } from '@blueprintjs/core'
import InputMask from 'react-input-mask'

export default function Detail(props) {
    const [data, setData] = React.useState(null)
    const [loading, setLoading] = React.useState(false)
    const uid = props.match.params.id

    const initData = async () => {
        let response = await GetCategory(uid)
        setData(response.data)
    }

    React.useEffect(() => {
        initData()
    }, [])

    const handleSwitch = (e) => {
        let index = e.target.getAttribute('id')
        let data_ = Object.assign({}, data)
        data_[index] = e.target.checked
        setData(data_)
    }

    const handleFields = (e) => {
        let index = e.target.getAttribute('id')
        let data_ = Object.assign({}, data)
        data_[index] = e.target.value
        setData(data_)
    }

    const handleFiles = (e) => {
        let index = e.target.getAttribute('id')
        let data_ = Object.assign({}, data)

        data_[index] = e.target.files[0]

        setData(data_)
    }

    const saveData = async (e) => {
        e.preventDefault()
        setLoading(true)
        let data_ = Object.assign({}, data)

        if (typeof data_.featured_image !== 'object') delete data_['featured_image']

        let form = new FormData()
        for (let x in data_) {
            if (data_[x] === null || data_[x] === 'null') data_[x] = ''
            form.append(x, data_[x])
        }

        try {
            await UpdateCategory(uid, form)
            initData()

            Toast('success', 'Registro actualizado exitosamente!')
        } catch (err) {
            console.log(err)
            Toast('danger', 'Ha ocurrido un error!')
        }

        setLoading(false)
    }

    if (data !== null) {
        return (
            <Container fluid>
                <DivLoading loading={loading}>
                    <form onSubmit={saveData}>
                        <Row>
                            <Col>
                                <Title>
                                    <h2>Datos de la Categoría</h2>
                                </Title>
                            </Col>
                            <Col xs="12">
                                <Div>{loading && <Spinner size="16" />}</Div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6" xs="12">
                                <Label className="form-group w-100">
                                    Código de la categoría
                                    <br />
                                    {data.id}
                                </Label>
                            </Col>
                            <Col md="6" xs="12">
                                <Label className="form-group w-100">
                                    Ocultar categoría <Required>*</Required>
                                    <ContentSwitch className="w-100">
                                        <Switch onChange={handleSwitch} id="is_hidden" checked={data.is_hidden} large LabelElement={'Estado'} innerLabelChecked="Si" innerLabel="No" />
                                    </ContentSwitch>
                                </Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6" xs="12">
                                <Label className="form-group w-100">
                                    Nombre de la categoría (Español)<Required>*</Required>
                                    <input required className="form-control" type="text" id="name" value={data.name === null ? '' : data.name} onChange={handleFields} />
                                </Label>
                            </Col>
                            <Col md="6" xs="12">
                                <Label className="form-group w-100">
                                    Nombre de la categoría (Ingles)
                                    <input required className="form-control" type="text" id="name_en" value={data.name_en === null ? '' : data.name_en} onChange={handleFields} />
                                </Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6" xs="12">
                                <Label className="form-group w-100">
                                    Nombre de la categoría (Frances)
                                    <input required className="form-control" type="text" id="name_fr" value={data.name_fr === null ? '' : data.name_fr} onChange={handleFields} />
                                </Label>
                            </Col>
                            <Col md="6" xs="12">
                                <Label className="form-group w-100">
                                    Nombre de la categoría (Ruso)
                                    <input required className="form-control" type="text" id="name_ru" value={data.name_ru === null ? '' : data.name_ru} onChange={handleFields} />
                                </Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6" xs="12">
                                <Label className="form-group w-100">
                                    Nombre de la categoría (Aleman)
                                    <input required className="form-control" type="text" id="name_gr" value={data.name_gr === null ? '' : data.name_gr} onChange={handleFields} />
                                </Label>
                            </Col>
                            <Col md="6" xs="12">
                                <Label className="form-group w-100">
                                    imagen destacada
                                    <br />
                                    <input type="file" accept="image/*" id="featured_image" onChange={handleFiles} />
                                    <br />
                                    <img className="mt-1" src={data.featured_image} height="90px;" alt="Debes guardar para ver la imagén" />
                                    <br />
                                </Label>
                            </Col>
                        </Row>

                        <Row>
                            <Col className="text-center mt-4">
                                <ButtonCancel type="button" onClick={initData}>
                                    Cancelar
                                </ButtonCancel>
                                <ButtonSuccess type="submit">Guardar</ButtonSuccess>
                            </Col>
                        </Row>
                    </form>
                </DivLoading>
            </Container>
        )
    } else {
        return <div></div>
    }
}

const Div = styled.div`
    width: 100%;
    position: relative;
    :hover .btn-edit {
        display: block;
    }
`

const Title = styled.div`
    position: relative;
    width: 100%;
    height: 20px;
    z-index: 0;

    h2 {
        padding-right: 10px;
        position: relative;
        float: left;
        left: 0;
        font-weight: bold;
        color: #57bc8f;
        font-size: 16px;
        background-color: #fff;
        z-index: 2;
    }
    :before {
        content: '';
        position: absolute;
        width: 100%;
        float: right;
        top: 50%;
        height: 1px;
        z-index: 1;
        background: #ccc;
    }
`

const ButtonCancel = styled.button`
    color: #666;
    border: none;
    background-color: #fff;
`
const ButtonSuccess = styled.button`
    color: #fff;
    background-color: #2bb05a;
    border: none;
    border-radius: 3px;
    padding: 4px 15px;
    margin-left: 15px;
`

const DivLoading = styled.div`
    transition: all 0.3s ease;
    width: 100%;
    opacity: ${(props) => (props.loading ? 0.6 : 1)};
    pointer-events: ${(props) => (props.loading ? 'none' : 'inherit')};
`

const ContentSwitch = styled.div`
    .bp3-control.bp3-switch.bp3-large:not(.bp3-align-right) .bp3-control-indicator {
        margin-left: -45px;
        outline: none !important;
        box-shadow: none !important;
    }
    .bp3-large {
        /* margin-bottom: 20px; */
        margin-top: 6px;
    }
`

const Required = styled.span`
    color: #f00;
`

const Label = styled.label`
    margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '10')}px;
    font-weight: bold;
`
