import React from 'react'
import { Row, Col, Container } from 'reactstrap'
import FeatherIcon from 'feather-icons-react'
import styled from 'styled-components'

export default function Name(props) {
    return (
        <Row>
            <Col md="4" sm="12" xl="3">
                <label className="form-group w-100">
                    <strong>Nombre (Español)</strong>
                    <p>{props.excursion.name}</p>
                </label>
            </Col>

            <Col md="4" sm="12" xl="3">
                <label className="form-group w-100">
                    <strong>Nombre (Inglés)</strong>
                    <p>{props.excursion.name_en}</p>
                </label>
            </Col>

            <Col md="4" sm="12" xl="3">
                <label className="form-group w-100">
                    <strong>Nombre (Alemán)</strong>
                    <p>{props.excursion.name_gr}</p>
                </label>
            </Col>

            <Col md="4" sm="12" xl="3">
                <label className="form-group w-100">
                    <strong>Nombre (Francés)</strong>
                    <p>{props.excursion.name_fr}</p>
                </label>
            </Col>
            <Col md="4" sm="12" xl="3">
                <label className="form-group w-100">
                    <strong>Nombre (Ruso)</strong>
                    <p>{props.excursion.name_ru}</p>
                </label>
            </Col>
        </Row>
    )
}
