import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import moment from 'moment'
import NumberFormat from 'react-number-format'
import FeatherIcon from 'feather-icons-react'
import { Popover, PopoverInteractionKind, Position, Spinner } from '@blueprintjs/core'
import Toast from '../Toast'
import Pagination from '../Pagination'

export default function Card(props) {
    const DisplayValue = (value, index) => {
        switch (index.toLowerCase()) {
            case 'timestamp':
                return moment(value).format('DD/MM/YYYY')

            case 'date':
                return moment(value).format('DD/MM/YYYY')

            case 'total':
                return (
                    <strong>
                        <NumberFormat displayType={'text'} prefix="$ " value={value} thousandSeparator="," />
                    </strong>
                )

            case 'uid':
                return <span>{value}</span>

            default:
                if (value !== null) {
                    return <span className="capitalize">{String(value).toLowerCase()}</span>
                } else {
                    return '-'
                }
        }
    }

    return (
        <>
            <Div>
                <Table>
                    <thead>
                        <tr>
                            {props.headers.map((el, key) => (
                                <th key={key}>{el}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {props.data.length === 0 && (
                            <tr>
                                <td colspan={props.headers.length}>{props.loading ? <Spinner size="29" /> : 'No hay registros.'}</td>
                            </tr>
                        )}
                        {props.data.map((row, key) => (
                            <tr key={key}>
                                {Object.keys(row).map((col, key_col) => (
                                    <Td key={key_col} col={col}>
                                        <Link to={`/${props.path}/${typeof row.uid !== 'undefined' ? row.uid : row.id}`}>{DisplayValue(row[col], col)}</Link>
                                    </Td>
                                ))}
                                {props.withActions && (
                                    <td>
                                        {' '}
                                        <Popover interactionKind={PopoverInteractionKind.CLICK} popoverClassName="bp3-popover-content-sizing" position={Position.BOTTOM}>
                                            <FeatherIcon icon="more-horizontal" size="14" stroke="#bbb" />
                                            <div>
                                                <OptionMenu>
                                                    <Link to={`/${props.path}/${typeof row.uid !== 'undefined' ? row.uid : row.id}`}>
                                                        <FeatherIcon icon="search" size="14" stroke="#bbb" /> Ver detalle
                                                    </Link>
                                                </OptionMenu>
                                                {props.canDelete && (
                                                    <OptionMenu>
                                                        <a
                                                            onClick={() => {
                                                                props.deleteItem(row.uid)
                                                                Toast('success', 'Registro eliminado exitosamente!')
                                                            }}>
                                                            <FeatherIcon icon="x" size="14" stroke="#ff4949" /> Eliminar
                                                        </a>
                                                    </OptionMenu>
                                                )}
                                                {props.canReload && (
                                                    <OptionMenu>
                                                        <a
                                                            onClick={() => {
                                                                props.reloadItem(row.uid)
                                                                Toast('success', 'Totems reiniciados exitosamente!')
                                                            }}>
                                                            <FeatherIcon icon="refresh-cw" size="14" stroke="#3478ff" /> Reiniciar Totems
                                                        </a>
                                                    </OptionMenu>
                                                )}
                                                {props.canDuplicate && (
                                                    <OptionMenu>
                                                        <a
                                                            onClick={() => {
                                                                props.duplicateItem(row.uid)
                                                                Toast('success', 'Totems reiniciados exitosamente!')
                                                            }}>
                                                            <FeatherIcon icon="copy" size="14" stroke="#999" /> Duplicar
                                                        </a>
                                                    </OptionMenu>
                                                )}
                                            </div>
                                        </Popover>
                                    </td>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Div>

            <Pagination count={props.count} changePage={props.changePage} current={props.current} setCurrent={props.setCurrent} />
        </>
    )
}

const Div = styled.div`
    width: 100%;
    overflow-x: auto;
    min-height: 700px;
`

const OptionMenu = styled.div`
    font-size: 14px;
    display: block;
    padding: 6px 15px;

    a {
        color: #777 !important;
        text-decoration: none;
    }
`

const Table = styled.table`
    margin-top: 15px;
    width: 100%;

    /* border: solid 1px #eee; */
    border-radius: 5px;
    border-spacing: 0;
    tr {
        transition: all 0.3s ease;
        :hover {
            background-color: #eee;
        }
    }

    th {
        text-align: center;
        padding: 4px 15px;
        font-size: 15px;
        border-top: solid 1px #eee;
    }

    td {
        font-size: 14px;
        .capitalize {
            text-transform: capitalize;
        }
        text-align: center;
        padding: 8px 15px;
        border: none;
        border-top: solid 1px #eee;
        a {
            color: #666;
            text-decoration: none;
        }
        svg {
            cursor: pointer;
        }
    }
`

const Td = styled.td`
    text-align: ${(props) => (props.col === 'uid' ? 'left !important' : 'center')};
    svg {
        margin-right: 15px;
    }
`
