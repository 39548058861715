import React from 'react'
import { Row, Col, Container } from 'reactstrap'
import FeatherIcon from 'feather-icons-react'
import styled from 'styled-components'
import { InputHotel, InputCategory, InputOperator, InputTypeExcursion } from '../../../../Components/Form/Fields'

export default function Detail(props) {
    return (
        <Row>
            <Col md="4" sm="12" xl="3">
                <label className="form-group w-100">
                    Hotel
                    <InputHotel
                        onChange={(value) => {
                            props.onChange('hotel', value)
                        }}
                        defaultValue={props.excursion.hotel}
                    />
                </label>
            </Col>

            <Col md="4" sm="12" xl="3">
                <label className="form-group w-100">
                    Categoría
                    <InputCategory
                        onChange={(value) => {
                            props.onChange('category', value)
                        }}
                        defaultValue={props.excursion.category}
                    />
                </label>
            </Col>

            <Col md="4" sm="12" xl="3">
                <label className="form-group w-100">
                    Operador
                    <InputOperator
                        onChange={(value) => {
                            props.onChange('operated_by', value)
                        }}
                        defaultValue={props.excursion.operated_by}
                    />
                </label>
            </Col>

            <Col md="4" sm="12" xl="3">
                <label className="form-group w-100">
                    Tipo de excursión
                    <InputTypeExcursion
                        onChange={(value) => {
                            props.onChange('excursion_type', value)
                        }}
                        defaultValue={props.excursion.excursion_type}
                    />
                </label>
            </Col>
        </Row>
    )
}
