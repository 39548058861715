import React from 'react'

export default function DebounceInput(props) {
    const [debounce, setDebounce] = React.useState(0)
    const handleFields = (e) => {
        clearTimeout(debounce)
        setDebounce(
            setTimeout(() => {
                let id = e.target.getAttribute('id')
                let value = e.target.value
                props.onChange(value, id)
            }, 500)
        )
    }

    return (
        <div>
            <input {...props} onChange={handleFields} />
        </div>
    )
}
