import React from 'react'
import styled from 'styled-components'
import { Row, Col, Container } from 'reactstrap'
import { GetReservation, UpdateReservation } from '../../API/Reservations'
import Toast from '../../Components/Toast'
import { Spinner, Switch } from '@blueprintjs/core'
import { InputHotel } from '../../Components/Form/Fields'
import moment from 'moment'
export default function Detail(props) {
    const [data, setData] = React.useState(null)
    const [loading, setLoading] = React.useState(false)
    const uid = props.match.params.id

    const initData = async () => {
        let response = await GetReservation(uid)
        console.log(response)
        setData(response.data)
    }

    React.useEffect(() => {
        initData()
    }, [])

    const handleSwitch = (e) => {
        let index = e.target.getAttribute('id')
        let data_ = Object.assign({}, data)
        data_[index] = e.target.checked
        setData(data_)
    }

    const handleFields = (e) => {
        let index = e.target.getAttribute('id')
        let data_ = Object.assign({}, data)
        data_[index] = e.target.value
        setData(data_)
    }

    const handleAdvancedFields = (index, value) => {
        let data_ = Object.assign({}, data)
        data_[index] = value
        setData(data_)
    }

    const saveData = async (e) => {
        e.preventDefault()
        setLoading(true)
        let data_ = Object.assign({}, data)

        if (data_.password === '' || data_.password === null) delete data_['password']

        let form = new FormData()
        for (let x in data_) {
            if (data_[x] === null || data_[x] === 'null') data_[x] = ''
            form.append(x, data_[x])
        }

        try {
            await UpdateReservation(uid, form)
            initData()

            Toast('success', 'Registro actualizado exitosamente!')
        } catch (err) {
            console.log(err)
            Toast('danger', 'Ha ocurrido un error!')
        }

        setLoading(false)
    }
    if (data !== null) {
        return (
            <Container fluid>
                <DivLoading loading={loading}>
                    <form onSubmit={saveData}>
                        <Row>
                            <Col>
                                <Title>
                                    <h2>Datos de la Reservación</h2>
                                </Title>
                            </Col>
                            <Col xs="12">
                                <Div>{loading && <Spinner size="16" />}</Div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="4" xs="12">
                                <Label className="form-group w-100">
                                    Excursión
                                    <br />
                                    <small>{data.excursion.name}</small>
                                </Label>
                            </Col>
                            <Col md="4" xs="12">
                                <Label className="form-group w-100">
                                    Código de la reservación
                                    <br />
                                    <small>{data.uid}</small>
                                </Label>
                            </Col>
                            <Col md="4" xs="12">
                                <Label className="form-group w-100">
                                    Hotel
                                    <br />
                                    <small>{data.hotel.name}</small>
                                </Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="4" xs="12">
                                <Label className="form-group w-100">
                                    Fecha de la reservación
                                    <br />
                                    <small>{moment(data.date).format('DD/MM/YYYY')}</small>
                                </Label>
                            </Col>
                            <Col md="4" xs="12">
                                <Label className="form-group w-100">
                                    Fecha de creación
                                    <br />
                                    <small>{moment(data.timestamp).format('DD/MM/YYYY')}</small>
                                </Label>
                            </Col>
                            <Col md="4" xs="12">
                                <Label className="form-group w-100">
                                    Totem
                                    <br />
                                    <small>{data.created_by.username}</small>
                                </Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="4" xs="12">
                                <Label className="form-group w-100">
                                    Lenguaje del tour
                                    <br />
                                    <small>{data.lang_tour}</small>
                                </Label>
                            </Col>
                            <Col md="4" xs="12">
                                <Label className="form-group w-100">
                                    Total (USD)
                                    <br />
                                    <small>USD$ {data.total}</small>
                                </Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Title>
                                    <h2>Datos del Cliente</h2>
                                </Title>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6" xs="12">
                                <Label className="form-group w-100">
                                    Nombre <br />
                                    <small>{data.full_name}</small>
                                </Label>
                            </Col>
                            <Col md="6" xs="12">
                                <Label className="form-group w-100">
                                    Correo electrónico <br />
                                    <small>{data.email}</small>
                                </Label>
                            </Col>

                            <Col md="6" xs="12">
                                <Label className="form-group w-100">
                                    Habitación <br />
                                    <small>{data.room}</small>
                                </Label>
                            </Col>
                        </Row>

                        {/* <Row>
                            <Col className="text-center mt-4">
                                <ButtonCancel type="button" onClick={initData}>
                                    Cancelar
                                </ButtonCancel>
                                <ButtonSuccess type="submit">Guardar</ButtonSuccess>
                            </Col>
                        </Row> */}
                    </form>
                </DivLoading>
            </Container>
        )
    } else {
        return <div></div>
    }
}

const Div = styled.div`
    width: 100%;
    position: relative;
    :hover .btn-edit {
        display: block;
    }
`

const Title = styled.div`
    position: relative;
    width: 100%;
    height: 20px;
    z-index: 0;

    h2 {
        padding-right: 10px;
        position: relative;
        float: left;
        left: 0;
        font-weight: bold;
        color: #57bc8f;
        font-size: 16px;
        background-color: #fff;
        z-index: 2;
    }
    :before {
        content: '';
        position: absolute;
        width: 100%;
        float: right;
        top: 50%;
        height: 1px;
        z-index: 1;
        background: #ccc;
    }
`

const ButtonCancel = styled.button`
    color: #666;
    border: none;
    background-color: #fff;
`
const ButtonSuccess = styled.button`
    color: #fff;
    background-color: #2bb05a;
    border: none;
    border-radius: 3px;
    padding: 4px 15px;
    margin-left: 15px;
`

const DivLoading = styled.div`
    transition: all 0.3s ease;
    width: 100%;
    opacity: ${(props) => (props.loading ? 0.6 : 1)};
    pointer-events: ${(props) => (props.loading ? 'none' : 'inherit')};
`

const ContentSwitch = styled.div`
    .bp3-control.bp3-switch.bp3-large:not(.bp3-align-right) .bp3-control-indicator {
        margin-left: -45px;
        outline: none !important;
        box-shadow: none !important;
    }
    .bp3-large {
        /* margin-bottom: 20px; */
        margin-top: 6px;
    }
`

const Required = styled.span`
    color: #f00;
`

const Label = styled.label`
    margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '10')}px;
    font-weight: bold;
    small {
        font-size: 13px;
    }
`
