import React from 'react'
import { Row, Col, Container } from 'reactstrap'
import FeatherIcon from 'feather-icons-react'
import styled from 'styled-components'

export default function Name(props) {
    return (
        <Row>
            <Col md="4" sm="12" xl="3">
                <label className="form-group w-100">
                    <strong>Nombre (Español)</strong>
                    <input onChange={props.onChange} value={props.excursion.name} id="name" className="form-control" />
                </label>
            </Col>

            <Col md="4" sm="12" xl="3">
                <label className="form-group w-100">
                    <strong>Nombre (Inglés)</strong>
                    <input onChange={props.onChange} value={props.excursion.name_en} id="name_en" className="form-control" />
                </label>
            </Col>

            <Col md="4" sm="12" xl="3">
                <label className="form-group w-100">
                    <strong>Nombre (Alemán)</strong>
                    <input onChange={props.onChange} value={props.excursion.name_gr} id="name_gr" className="form-control" />
                </label>
            </Col>

            <Col md="4" sm="12" xl="3">
                <label className="form-group w-100">
                    <strong>Nombre (Francés)</strong>
                    <input onChange={props.onChange} value={props.excursion.name_fr} id="name_fr" className="form-control" />
                </label>
            </Col>
            <Col md="4" sm="12" xl="3">
                <label className="form-group w-100">
                    <strong>Nombre (Ruso)</strong>
                    <input onChange={props.onChange} value={props.excursion.name_ru} id="name_ru" className="form-control" />
                </label>
            </Col>
        </Row>
    )
}
