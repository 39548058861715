import React from 'react'
import styled from 'styled-components'
import { Container } from 'reactstrap'
import { Route, Switch } from 'react-router-dom'
import ListExcursions from '../Pages/Excursions'
import ListReservations from '../Pages/Reservations'
import ListTotems from '../Pages/Totems'
import ListHotels from '../Pages/Hotels'
import ListOperators from '../Pages/Operators'
import DetailExcursions from '../Pages/Excursions/detail'
import DetailHotels from '../Pages/Hotels/detail'
import DetailReservations from '../Pages/Reservations/detail'
import DetailTotems from '../Pages/Totems/detail'
import DetailOperators from '../Pages/Operators/detail'
import ListCategories from '../Pages/Categories'
import DetailCategory from '../Pages/Categories/detail'
import Home from '../Pages/Home'
import NotFound from '../Pages/NotFound'
export default function Content(props) {
    return (
        <ContentContainer>
            <Container fluid>
                <Switch>
                    <Route path="/excursiones" exact component={ListExcursions} />
                    <Route path="/excursiones/:id" exact component={DetailExcursions} />
                    <Route path="/hoteles" exact component={ListHotels} />
                    <Route path="/hoteles/:id" exact component={DetailHotels} />
                    <Route path="/categorias" exact component={ListCategories} />
                    <Route path="/categorias/:id" exact component={DetailCategory} />
                    <Route path="/reservaciones" exact component={ListReservations} />
                    <Route path="/reservaciones/:id" exact component={DetailReservations} />
                    <Route path="/totems" exact component={ListTotems} />
                    <Route path="/totems/:id" exact component={DetailTotems} />
                    <Route path="/operadores" exact component={ListOperators} />
                    <Route path="/operadores/:id" exact component={DetailOperators} />
                    <Route path="/" exact component={Home} />
                    {/* <Route path="/excursiones/:id" exact component={ViewBudget} /> */}
                    <Route component={NotFound} />
                </Switch>
            </Container>
        </ContentContainer>
    )
}

const ContentContainer = styled.div`
    min-height: 97vh;
    background-color: #eee;
    padding: 20px;
    .container-fluid {
        background-color: #fff !important;
        border-radius: 5px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
`
