import { API } from './main'

export function FilterOperators(qs) {
    return API(`members/p/operators/${qs}`)
}

export function CreateOperator(data) {
    return API.post(`members/p/operators/`, data)
}

export function UpdateOperator(uid, data) {
    return API.patch(`members/p/operators/${uid}/`, data)
}

export function DeleteOperator(uid) {
    return API.delete(`members/p/operators/${uid}/`)
}
