import React from 'react'
import { Modal, ModalHeader } from 'reactstrap'
import { CreateHotel } from '../../API/Core'
import Form from '../Form/Form'
import { FIELDS_HOTEL } from '../../helpers/fields'

export default function ModalCreateTotem(props) {
    return (
        <div>
            <Modal isOpen={props.open} toggle={props.onClose}>
                <ModalHeader toggle={props.onClose}>Nuevo Hotel</ModalHeader>
                <Form SendData={CreateHotel} refreshList={props.refreshList} onClose={props.onClose} fields={FIELDS_HOTEL} />
            </Modal>
        </div>
    )
}
