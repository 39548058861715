import React from 'react'
import { Row, Col, Container } from 'reactstrap'
import FeatherIcon from 'feather-icons-react'
import styled from 'styled-components'

export default function Media(props) {
    return (
        <Row>
            <Col md="12">
                <label className="form-group w-100">
                    <strong>Imagen destacada</strong>
                    <input onChange={props.handleFiles} accept="image/*" id="featured_image" type="file" className="form-control" />
                </label>
            </Col>
            <Col md="12">
                <label className="form-group w-100">
                    <strong>Imagen grande</strong>
                    <input onChange={props.handleFiles} accept="image/*" id="big_image" type="file" className="form-control" />
                </label>
            </Col>
            <Col md="12">
                <label className="form-group w-100">
                    <strong>Itenerario</strong>
                    <input onChange={props.handleFiles} accept="image/*" id="itinerary" type="file" className="form-control" />
                </label>
            </Col>
            <Col md="12">
                <label className="form-group w-100">
                    <strong>Youtube iframe</strong>
                    <input onChange={props.onChange} value={props.excursion.youtubeiframe} id="youtubeiframe" type="text" className="form-control" />
                </label>
            </Col>
        </Row>
    )
}
