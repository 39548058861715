import React from 'react'
import { Row, Col, Container } from 'reactstrap'
import FeatherIcon from 'feather-icons-react'
import styled from 'styled-components'
import { Editor } from 'react-draft-wysiwyg'
import DraftToHtml from 'draftjs-to-html'
import { EditorState, ContentState, convertFromHTML, convertToRaw } from 'draft-js'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

export default function Description(props) {
    const [showEditor, setShowEditor] = React.useState([true, true, true, true, true])

    const onEditorStateChange = (id, editorState) => {
        props.onChange(id, DraftToHtml(editorState))
    }

    const ReturnHtml = (value) => {
        let html_string = convertFromHTML(value === null ? '' : value)
        let html = ContentState.createFromBlockArray(html_string)
        return convertToRaw(html)
    }

    const handleShowEditor = (e, show) => {
        let index = e.target.getAttribute('data-index')
        let showEditor_ = Array.from(showEditor)
        showEditor_[index] = true
        setShowEditor(showEditor_)
    }

    const handleHideEditor = (e, show) => {
        let index = e.target.getAttribute('data-index')
        let showEditor_ = Array.from(showEditor)
        showEditor_[index] = false
        setShowEditor(showEditor_)
    }

    return (
        <Content className="w-100">
            <Row>
                <Col md="12" className="mb-4">
                    <label className="form-group w-100">
                        Descripción <strong>(Español)</strong>
                        <ActionsEditor>
                            <small data-index="0" onClick={handleShowEditor}>
                                HTML
                            </small>{' '}
                            |{' '}
                            <small data-index="0" onClick={handleHideEditor}>
                                Texto
                            </small>
                        </ActionsEditor>
                        {showEditor[0] ? (
                            <Editor
                                initialContentState={ReturnHtml(props.excursion.description)}
                                onContentStateChange={(editorState) => {
                                    onEditorStateChange('description', editorState)
                                }}
                            />
                        ) : (
                            <textarea onChange={props.onChangeFields} id="description" value={props.excursion.description} className="form-control" />
                        )}
                    </label>
                </Col>
                <Col md="12" className="mb-4">
                    <label className="form-group w-100">
                        Descripción <strong>(Inglés)</strong>
                        <ActionsEditor>
                            <small data-index="1" onClick={handleShowEditor}>
                                HTML
                            </small>{' '}
                            |{' '}
                            <small data-index="1" onClick={handleHideEditor}>
                                Texto
                            </small>
                        </ActionsEditor>
                        {showEditor[1] ? (
                            <Editor
                                initialContentState={ReturnHtml(props.excursion.description_en)}
                                onContentStateChange={(editorState) => {
                                    onEditorStateChange('description_en', editorState)
                                }}
                            />
                        ) : (
                            <textarea onChange={props.onChangeFields} id="description_en" value={props.excursion.description_en} className="form-control" />
                        )}
                    </label>
                </Col>
                <Col md="12" className="mb-4">
                    <label className="form-group w-100">
                        Descripción <strong>(Alemán)</strong>{' '}
                        <ActionsEditor>
                            <small data-index="2" onClick={handleShowEditor}>
                                HTML
                            </small>{' '}
                            |{' '}
                            <small data-index="2" onClick={handleHideEditor}>
                                Texto
                            </small>
                        </ActionsEditor>
                        {showEditor[2] ? (
                            <Editor
                                initialContentState={ReturnHtml(props.excursion.description_gr)}
                                onContentStateChange={(editorState) => {
                                    onEditorStateChange('description_gr', editorState)
                                }}
                            />
                        ) : (
                            <textarea onChange={props.onChangeFields} id="description_gr" value={props.excursion.description_gr} className="form-control" />
                        )}
                    </label>
                </Col>
                <Col md="12" className="mb-4">
                    <label className="form-group w-100">
                        Descripción <strong>(Francés)</strong>
                        <ActionsEditor>
                            <small data-index="3" onClick={handleShowEditor}>
                                HTML
                            </small>{' '}
                            |{' '}
                            <small data-index="3" onClick={handleHideEditor}>
                                Texto
                            </small>
                        </ActionsEditor>
                        {showEditor[3] ? (
                            <Editor
                                initialContentState={ReturnHtml(props.excursion.description_fr)}
                                onContentStateChange={(editorState) => {
                                    onEditorStateChange('description_fr', editorState)
                                }}
                            />
                        ) : (
                            <textarea onChange={props.onChangeFields} id="description_fr" value={props.excursion.description_fr} className="form-control" />
                        )}
                    </label>
                </Col>
                <Col md="12" className="mb-4">
                    <label className="form-group w-100">
                        Descripción <strong>(Ruso)</strong>
                        <ActionsEditor>
                            <small data-index="4" onClick={handleShowEditor}>
                                HTML
                            </small>{' '}
                            |{' '}
                            <small data-index="4" onClick={handleHideEditor}>
                                Texto
                            </small>
                        </ActionsEditor>
                        {showEditor[4] ? (
                            <Editor
                                initialContentState={ReturnHtml(props.excursion.description_ru)}
                                onContentStateChange={(editorState) => {
                                    onEditorStateChange('description_ru', editorState)
                                }}
                            />
                        ) : (
                            <textarea onChange={props.onChangeFields} id="description_ru" value={props.excursion.description_ru} className="form-control" />
                        )}
                    </label>
                </Col>
            </Row>
        </Content>
    )
}

const Content = styled.div`
    .rdw-editor-main {
        height: 280px;
        overflow: auto;
        box-sizing: border-box;
        border: solid 1px #eee;
    }

    textarea {
        height: 300px;
    }
`

const ActionsEditor = styled.div`
    display: inline;
    margin-left: 20px;
    small {
        margin-left: 3px;
        margin-right: 3px;
        cursor: pointer;
        color: #3075d6;
    }
`
