import React from 'react'
import Sidebar from './sidebar'
import SidebarMobile from './sidebarMobile'
import Content from './content'
import Footer from './footer'
import styled from 'styled-components'

export default function Main(props) {
    return (
        <Container>
            <Sidebar_>
                <Sidebar className="sidebar-desktop" />
                <SidebarMobile className="sidebar-mobile" />
            </Sidebar_>
            <Content_>
                <Content />
                <Footer />
            </Content_>
        </Container>
    )
}

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    @media screen and (max-width: 768px) {
        flex-flow: column wrap !important;
    }
`

const Sidebar_ = styled.aside`
    position: fixed;
    z-index: 99;
    min-height: 100vh;
    width: 280px;
    .sidebar-mobile {
        display: none;
    }
    .sidebar-desktop {
        display: block;
    }
    @media screen and (max-width: 768px) {
        width: 100%;
        min-height: auto;
        .sidebar-mobile {
            display: block !important;
        }
        .sidebar-desktop {
            display: none !important;
        }
    }
`

const Content_ = styled.section`
    width: calc(100% - 280px);
    margin-left: 280px;
    @media screen and (max-width: 768px) {
        width: 100%;
        min-height: 95vh;
        margin-left: 0px;
        margin-top: 40px;
    }
`
