import React from 'react'
import { Row, Col } from 'reactstrap'
import styled from 'styled-components'
import { Switch } from '@blueprintjs/core'

export default function Config(props) {
    return (
        <Row>
            <Col md="4" sm="4" xl="4">
                <ContentSwitch className="w-100">
                    <Switch onChange={props.onChange} id="is_active" checked={props.excursion.is_active} large labelElement={'Estado'} innerLabelChecked="Activo" innerLabel="Inactivo" />

                    <Switch onChange={props.onChange} id="can_purchase" checked={props.excursion.can_purchase} large labelElement={'Permitir compra'} innerLabelChecked="Si" innerLabel="No" />
                </ContentSwitch>
            </Col>
            <Col md="4" sm="4" xl="4">
                <ContentSwitch className="w-100">
                    <Switch onChange={props.onChange} id="is_featured" checked={props.excursion.is_featured} large labelElement={'Destacada'} innerLabelChecked="Si" innerLabel="No" />

                    <Switch onChange={props.onChange} id="require_room" checked={props.excursion.require_room} large labelElement={'Requiere habitación'} innerLabelChecked="Si" innerLabel="No" />
                </ContentSwitch>
            </Col>
            <Col md="4" sm="4" xl="4">
                <ContentSwitch className="w-100">
                    <Switch onChange={props.onChange} id="only_reservation" checked={props.excursion.only_reservation} large labelElement={'Solo reservación'} innerLabelChecked="Si" innerLabel="No" />
                </ContentSwitch>
            </Col>
        </Row>
    )
}

const ContentSwitch = styled.div`
    .bp3-control.bp3-switch.bp3-large:not(.bp3-align-right) .bp3-control-indicator {
        margin-left: -45px;
        outline: none !important;
        box-shadow: none !important;
    }
    .bp3-large {
        margin-bottom: 20px;
        margin-top: 6px;
    }
`
