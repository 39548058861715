import { API } from './main'

export function FilterReservations(qs) {
    return API(`reservations/p/${qs}`)
}

// export function FilterTotems(qs) {
//     return API(`p/totems/${qs}`)
// }

export function GetReservation(uid) {
    return API(`reservations/p/${uid}/`)
}

export function UpdateReservation(uid, data) {
    return API.patch(`reservations/p/${uid}/`, data)
}

export function DeleteReservation(uid) {
    return API.delete(`reservations/p/${uid}/`)
}

// export function GetTotem(uid) {
//     return API(`core/p/totems/${uid}/`)
// }
