import React from 'react'
import styled from 'styled-components'
import { Row, Col, Container } from 'reactstrap'
import Table from '../../Components/Tables/Table'
import ModalCreateCategory from '../../Components/Modals/ModalCreateCategory'
import DebounceInput from '../../Components/Form/Fields/DebounceInput'
import { FilterCategoriesDashboard, DeleteCategory } from '../../API/Excursions'

const CATEGORIES_HEADERS = ['Código', 'Nombre', 'Estado', 'Excursiones']

export default function Card(props) {
    const [categories, setCategories] = React.useState([])
    const [openModal, setOpenModal] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [count, setCount] = React.useState(0)
    const [current, setCurrent] = React.useState(1)

    const InitData = async (page, search) => {
        setLoading(true)
        let search_ = typeof search !== 'undefined' ? search : ''
        let response = await FilterCategoriesDashboard(`?page_size=15&page=${page}&search=${search_}`)
        setCurrent(page)
        setCategories(response.data.results)
        setCount(response.data.count)
        setLoading(false)
    }

    React.useEffect(() => {
        InitData(1)
    }, [])

    return (
        <Container fluid>
            <Row>
                <Col>
                    <Title>Listado de Categorías</Title>
                </Col>
            </Row>
            <Row>
                <Col>
                    <ButtonNew
                        onClick={() => {
                            setOpenModal(true)
                        }}>
                        Agregar
                    </ButtonNew>
                    <ModalCreateCategory refreshList={() => InitData(1)} open={openModal} onClose={() => setOpenModal(false)} />
                </Col>
                <Col xs="12" md="4">
                    <DebounceInput
                        placeholder="Buscar..."
                        onChange={(value) => {
                            InitData(1, value)
                        }}
                        className="form-control"
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Table
                        loading={loading}
                        data={categories}
                        path={'categorias'}
                        count={count}
                        current={current}
                        setCurrent={setCurrent}
                        headers={CATEGORIES_HEADERS}
                        changePage={InitData}
                        withActions
                        canDelete
                        canReload
                        deleteItem={async (uid) => {
                            await DeleteCategory(uid)
                            InitData(1)
                        }}
                    />
                </Col>
            </Row>
        </Container>
    )
}

const Title = styled.h1`
    font-size: 20px;
    margin-bottom: 10px;
    color: #51ba8a;
    font-weight: bold;
`

const ButtonNew = styled.button`
    background-color: #129544;
    border-radius: 3px;
    border: none;
    transition: all 0.3s ease;
    outline: none;
    box-shadow: none;
    color: #fff;
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    svg {
        transition: all 0.3s ease;
    }
    :hover {
        opacity: 0.9;
    }
`
