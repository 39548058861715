import React from 'react'
import { Row, Col, Container } from 'reactstrap'
import FeatherIcon from 'feather-icons-react'
import styled from 'styled-components'

export default function Detail(props) {
    console.log(props)
    return (
        <Row>
            <Col md="4" sm="12" xl="3">
                <label className="form-group w-100">
                    <strong>Hotel</strong>
                    <p>{props.excursion.hotel.label}</p>
                </label>
            </Col>

            <Col md="4" sm="12" xl="3">
                <label className="form-group w-100">
                    <strong>Categoría</strong>
                    <p>{props.excursion.category.label}</p>
                </label>
            </Col>

            <Col md="4" sm="12" xl="3">
                <label className="form-group w-100">
                    <strong>Operador</strong>
                    <p>{props.excursion.operated_by.label}</p>
                </label>
            </Col>

            <Col md="4" sm="12" xl="3">
                <label className="form-group w-100">
                    <strong>Tipo de excursión</strong>
                    <p>{props.excursion.excursion_type.label}</p>
                </label>
            </Col>
        </Row>
    )
}
