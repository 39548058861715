import axios from 'axios'

export const baseURL = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? 'http://localhost:8000/api/v1' : 'https://secure.puntacanaapp.com.do/api/v1'

let config = {
    headers: {
        'Content-Type': 'application/json',
        Vary: 'Accept, Origin, Cookie',
        Allow: 'POST, OPTIONS',
        'X-Frame-Options': 'DENY',
        'Access-Control-Allow-Origin': '*',
        'Content-Length': '112',
        'X-Content-Type-Options': 'nosniff',
    },
}

export const API = axios.create({
    baseURL: baseURL,
    config,
})

export function updateHeaders(headers) {
    let _headersName = Object.keys(headers)
    for (let i in _headersName) {
        API.defaults.headers.common[_headersName[i]] = headers[_headersName[i]]
    }
}
