import React from 'react'
import styled from 'styled-components'
import logo from '../../logo-puntacanaapp.png'
export default function Logo(props) {
    return (
        <Content {...props}>
            <img alt="a_" src={logo} />
        </Content>
    )
}

const Content = styled.div`
    width: ${(props) => (props.width ? props.width : 'auto')};
    height: ${(props) => (props.height ? props.height : 'auto')};
    padding: 10px;
    margin: 0 auto;
    text-align: center;
    img {
        width: ${(props) => (props.width ? props.width : 'auto')};
        height: ${(props) => (props.height ? props.height : 'auto')};
    }
`
