import React from 'react'
import { Row, Col, Container } from 'reactstrap'
import FeatherIcon from 'feather-icons-react'
import styled from 'styled-components'
import ReactPlayer from 'react-player'

export default function Media(props) {
    return (
        <Row>
            <Col md="12">
                <label className="form-group w-100">
                    <strong>Youtube iframe</strong>
                    {props.excursion.youtubeiframe !== null && props.excursion.youtubeiframe !== '' ? (
                        <ReactPlayer loop controls={false} width="100%" height="550px" url={props.excursion.youtubeiframe} playing />
                    ) : (
                        <p>No se ha ingresado una URL válida.</p>
                    )}
                </label>
            </Col>
            <Col md="3">
                <label className="form-group w-100">
                    <strong>Imagen destacada</strong>
                    <Div>
                        <Img src={props.excursion.featured_image} />
                    </Div>
                </label>
            </Col>
            <Col md="3">
                <label className="form-group w-100">
                    <strong>Itenerario</strong>
                    <Div>
                        <Img src={props.excursion.itinerary} />
                    </Div>
                </label>
            </Col>
            <Col md="6">
                <label className="form-group w-100">
                    <strong>Imagen grande</strong>
                    <div>
                        <Img src={props.excursion.big_image} />
                    </div>
                </label>
            </Col>
        </Row>
    )
}

const Img = styled.img`
    max-width: 100%;
    max-height: 100%;
    border-radius: 3px;
`

const Div = styled.div`
    display: block;
    height: 300px;
`
