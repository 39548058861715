import React from 'react'
import AsyncSelect from 'react-select/async'
import { FilterOperators } from '../../../API/Members'

export default function InputOperator(props) {
    const [debounce, setDebounce] = React.useState(null)
    const loadOptions = (inputValue, callback) => {
        let debounce_ = debounce
        clearTimeout(debounce_)
        debounce_ = setTimeout(async () => {
            try {
                let response = await FilterOperators(`?search=${inputValue}&page_size=10`)
                let data = []
                let results = response.data.results
                for (let x = 0; x < results.length; x++) {
                    data.push({ label: results[x].name, value: results[x].uid })
                }
                return callback(data)
            } catch (error) {
                console.log(error)
                return callback(null)
            }
        }, 600)
        setDebounce(debounce_)
    }
    const handleOnchange = (e) => {
        if (e !== null) {
            props.onChange(e.value)
        } else {
            props.onChange(e)
        }
    }
    return (
        <div>
            <AsyncSelect loadOptions={loadOptions} defaultValue={props.defaultValue} defaultOptions isClearable onChange={handleOnchange} />
        </div>
    )
}
