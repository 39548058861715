import React from 'react'
import { Row, Col, Container } from 'reactstrap'
import FeatherIcon from 'feather-icons-react'
import styled from 'styled-components'

export default function Pagination(props) {
    const [pages, setPages] = React.useState(1)

    const initData = () => {
        let aux = props.count / 15
        let pages = aux - parseInt(aux)
        if (pages > 0) {
            setPages(parseInt(aux) + 1)
        } else {
            setPages(parseInt(aux))
        }
    }

    React.useEffect(() => {
        initData()
    }, [props.count])

    const changePage = (e) => {
        let page = e.target.getAttribute('data-index')
        props.setCurrent(page)
        props.changePage(page)
    }

    const returnPages = () => {
        let aux = []

        let min = pages / 2 - 5
        let max = pages / 2 + 5
        if (min < 0) {
            max = max + min * -1
            let aux_pages = max - pages
            max = max - aux_pages
            min = 0
        }
        for (let x = min; x < max; x++) {
            aux.push(
                <Page active={parseInt(props.current) === x + 1} onClick={changePage} data-index={x + 1}>
                    {x + 1}
                </Page>
            )
        }
        return aux
    }
    return (
        <Pagination_>
            {pages > 1 && props.current > 1 && (
                <Page onClick={changePage} data-index={1}>
                    <small>Primera</small>
                </Page>
            )}
            {pages > 1 && props.current > 1 && (
                <Page onClick={changePage} data-index={parseInt(props.current) - 1}>
                    <FeatherIcon icon="chevron-left" stroke="#777" size="14" />
                </Page>
            )}
            {returnPages()}
            {pages > 1 && props.current < pages && (
                <Page onClick={changePage} data-index={parseInt(props.current) + 1}>
                    <FeatherIcon icon="chevron-right" stroke="#777" size="14" />
                </Page>
            )}
            {pages > 1 && props.current < pages && (
                <Page onClick={changePage} data-index={pages}>
                    <small>Última</small>
                </Page>
            )}
        </Pagination_>
    )
}

const Page = styled.span`
    padding: 0 5px;
    cursor: pointer;
    color: ${(props) => (props.active ? '#48B688' : 'inherit')};
    font-weight: ${(props) => (props.active ? 'bold' : 'normal')};
    text-decoration: ${(props) => (props.active ? 'underline' : 'none')};
    small,
    svg {
        pointer-events: none;
    }
`

const Pagination_ = styled.div`
    border-top: solid 1px #eee;
    margin-top: 25px;
    padding-top: 15px;
    width: 100%;
    text-align: center;
`
