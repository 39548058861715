import React from 'react'
import styled from 'styled-components'
import { Row, Col, Container } from 'reactstrap'
import Table from '../../Components/Tables/Table'
import { FilterReservations, DeleteReservation } from '../../API/Reservations'
import DebounceInput from '../../Components/Form/Fields/DebounceInput'

const RESERVATIONS_HEADERS = ['Código', 'Nombre', 'Correo Electrónico', 'Total', 'Excursión', 'Hotel', 'Fecha Creación', 'Fecha Excursión']

export default function Card(props) {
    const [reservations, setReservations] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [count, setCount] = React.useState(0)
    const [current, setCurrent] = React.useState(1)

    const InitData = async (page, search) => {
        setLoading(true)
        let search_ = typeof search !== 'undefined' ? search : ''
        setCurrent(page)
        let response = await FilterReservations(`?page_size=15&page=${page}&search=${search_}`)
        setReservations(response.data.results)
        setCount(response.data.count)
        setLoading(false)
    }

    React.useEffect(() => {
        InitData(1)
    }, [])

    return (
        <Container fluid>
            <Row>
                <Col>
                    <Title>Listado de Reservaciones</Title>
                </Col>
            </Row>
            <Row>
                <Col xs="12" md={{ size: 4, offset: 8 }} className="text-right">
                    <DebounceInput
                        placeholder="Buscar..."
                        onChange={(value) => {
                            InitData(1, value)
                        }}
                        className="form-control"
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Table
                        loading={loading}
                        data={reservations}
                        count={count}
                        current={current}
                        setCurrent={setCurrent}
                        path={'reservaciones'}
                        changePage={InitData}
                        headers={RESERVATIONS_HEADERS}
                        withActions
                        deleteItem={async (uid) => {
                            await DeleteReservation(uid)
                            InitData(1)
                        }}
                    />
                </Col>
            </Row>
        </Container>
    )
}

const Title = styled.h1`
    font-size: 20px;

    margin-bottom: 10px;
    color: #51ba8a;
    font-weight: bold;
`
const ButtonNew = styled.button`
    background-color: #129544;
    border-radius: 3px;
    border: none;
    transition: all 0.3s ease;
    outline: none;
    box-shadow: none;
    color: #fff;
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    svg {
        transition: all 0.3s ease;
    }
    :hover {
        opacity: 0.9;
    }
`
