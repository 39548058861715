import React from 'react'
import styled from 'styled-components'
import { Row, Col, Container } from 'reactstrap'
import Logo from '../Components/Sidebar/logo'
import Links from '../Components/Sidebar/linksMobile'

export default function SidebarMobile(props) {
    return (
        <ContentSidebar {...props}>
            <Container>
                <Row>
                    <Col sm={3} md={3} xs={3}>
                        <Logo height="25px" />
                    </Col>
                    <Col sm={9} md={9} xs={9}>
                        <Links />
                    </Col>
                </Row>
            </Container>
        </ContentSidebar>
    )
}

const ContentSidebar = styled.div`
    display: block;
    position: relative;
    float: left;
    height: 5vh;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 3px 10px #ddd;
`
