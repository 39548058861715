import React from 'react'
import { Row, Col } from 'reactstrap'
import styled from 'styled-components'
import { InputCategory, InputHotel, InputTypeExcursion, InputOperator } from './Fields'
import { Button, ModalBody, ModalFooter } from 'reactstrap'
import Toast from '../Toast'
import InputMask from 'react-input-mask'
import { Intent, Spinner } from '@blueprintjs/core'
export default function Card(props) {
    const [data, setData] = React.useState()
    const [loading, setLoading] = React.useState(false)

    const onChange = (e) => {
        let index = e.target.getAttribute('id')
        let data_ = Object.assign({}, data)
        data_[index] = e.target.value
        console.log(data_[index])
        setData(data_)
    }

    const onChangeFiles = (e) => {
        let index = e.target.getAttribute('id')
        let data_ = Object.assign({}, data)
        data_[index] = e.target.files[0]
        setData(data_)
    }

    const onChangeSelect = (value, index) => {
        let data_ = Object.assign({}, data)
        data_[index] = value
        setData(data_)
    }

    const onSend = async (e) => {
        setLoading(true)
        e.preventDefault()
        try {
            let form = new FormData()
            for (let x in data) {
                form.append(x, data[x])
            }
            await props.SendData(form)
            if (props.refreshList) props.refreshList()
            props.onClose()
            Toast('success', 'Registro creado exitosamente!')
        } catch (error) {
            console.log(error)
            Toast('danger', 'Ha ocurrido un error')
        }
        setLoading(false)
    }

    const returnField = (field) => {
        switch (field.type) {
            case 'text':
                return <input className="form-control" type="text" required={field.required} id={field.id} onChange={onChange} />
            case 'number':
                return <input className="form-control" type="number" required={field.required} id={field.id} onChange={onChange} />
            case 'email':
                return <input className="form-control" type="email" required={field.required} id={field.id} onChange={onChange} />
            case 'file':
                return <input className="form-control" type="file" required={field.required} accept="image/*" id={field.id} onChange={onChangeFiles} />
            case 'phone':
                return <InputMask className="form-control" required={field.required} id={field.id} mask="+9 (999) 999 9999" onChange={onChange} />
            case 'textarea':
                return <textarea className="form-control" type="email" required={field.required} id={field.id} onChange={onChange} />
            case 'hotel':
                return (
                    <InputHotel
                        id={field.id}
                        required={field.required}
                        onChange={(value) => {
                            onChangeSelect(value, field.id)
                        }}
                    />
                )
            case 'excursion_type':
                return (
                    <InputTypeExcursion
                        className="form-control"
                        id={field.id}
                        required={field.required}
                        onChange={(value) => {
                            onChangeSelect(value, field.id)
                        }}
                    />
                )
            case 'category':
                return (
                    <InputCategory
                        className="form-control"
                        id={field.id}
                        required={field.required}
                        onChange={(value) => {
                            onChangeSelect(value, field.id)
                        }}
                    />
                )
            case 'operator':
                return (
                    <InputOperator
                        className="form-control"
                        id={field.id}
                        required={field.required}
                        onChange={(value) => {
                            onChangeSelect(value, field.id)
                        }}
                    />
                )

            default:
                break
        }
    }

    return (
        <form onSubmit={onSend}>
            <ModalBody>
                {props.fields.map((e, key) => (
                    <Row key={key}>
                        <Col>
                            <label className="form-group w-100">
                                {e.label} {e.required && <Required>*</Required>}
                                <br />
                                <small>{e.info}</small>
                                {returnField(e)}
                            </label>
                        </Col>
                    </Row>
                ))}
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={props.onClose}>
                    Cancelar
                </Button>
                <Button color="success" disabled={loading} type="submit">
                    {loading ? <Spinner intent={Intent.NONE} size={'24'} /> : 'Crear'}
                </Button>{' '}
            </ModalFooter>
        </form>
    )
}

const Required = styled.span`
    color: #f00;
`
