import React from 'react'
import FeatherIcon from 'feather-icons-react'
import styled from 'styled-components'

export default function FuncionName(props) {
    return (
        <div>
            {!props.editTab ? (
                <EditButton onClick={props.handleEdit} data-index={props.dataIndex} className={props.className}>
                    <FeatherIcon icon="edit-2" size="17" stroke="#666" />
                </EditButton>
            ) : (
                <>
                    <ConfirmEdit onClick={props.saveData} data-index={props.dataIndex} className={props.className}>
                        <FeatherIcon icon="check" size="17" stroke="#05b705" />
                    </ConfirmEdit>
                    <CancelEdit onClick={props.handleEdit} data-index={props.dataIndex} className={props.className}>
                        <FeatherIcon icon="x" size="17" stroke="#de0f0f" />
                    </CancelEdit>
                </>
            )}
        </div>
    )
}

const EditButton = styled.button`
    position: absolute;
    float: right;
    right: 20px;
    border: none;
    box-shadow: none !important;
    outline: none !important;
    background-color: transparent;
    display: none;
    top: -10px;
    z-index: 20;
    cursor: pointer;
    svg {
        pointer-events: none;
    }
`
const CancelEdit = styled.button`
    position: absolute;
    float: right;
    right: 20px;
    border: none;
    box-shadow: none !important;
    outline: none !important;
    background-color: transparent;
    display: none;
    top: -10px;
    z-index: 20;
    cursor: pointer;
    svg {
        pointer-events: none;
    }
`
const ConfirmEdit = styled.button`
    position: absolute;
    float: right;
    right: 40px;
    border: none;
    box-shadow: none !important;
    outline: none !important;
    background-color: transparent;
    display: none;
    top: -10px;
    z-index: 20;
    cursor: pointer;
    svg {
        pointer-events: none;
    }
`
