import React from 'react'
import FeatherIcon from 'feather-icons-react'
import styled from 'styled-components'
import Cookie from 'universal-cookie'

const cookies = new Cookie()

export default function Logout(props) {
    const handleLogout = () => {
        cookies.remove('hotel')
        cookies.remove('token')
        cookies.remove('sessionid')
        cookies.remove('uid')
        cookies.remove('csrftoken')
        window.location.href = '/'
    }
    return (
        <Li className="mt-3">
            <LinkStyled onClick={handleLogout}>
                <FeatherIcon icon="log-out" size="14" stroke="#444" /> Salir
            </LinkStyled>
        </Li>
    )
}

const Li = styled.li`
    width: 100%;
    position: relative;
    float: left;
    text-align: left;
`
const LinkStyled = styled.span`
    cursor: pointer;
    width: 100%;
    height: 100%;
    padding-top: 9px;
    padding-bottom: 9px;
    padding-left: 25px;
    position: relative;
    float: left;
    color: #444;
    text-decoration: none;
    font-size: 14px;
    text-decoration: none !important;
    svg {
        margin-right: 10px;
    }
`
