import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'reactstrap'
import { GetResume } from '../../API/Core'
import FeatherIcon from 'feather-icons-react'

export default function Home(props) {
    const [data, setData] = React.useState([])

    const initData = async () => {
        let response = await GetResume()
        setData(response.data)
    }

    React.useEffect(() => {
        initData()
    }, [])

    const ReturnIcon = (index) => {
        switch (index) {
            case 'reservations':
                return (
                    <IconContent bg="#415799" className="icon">
                        <FeatherIcon icon="file-text" size="20" stroke="#fff" />
                    </IconContent>
                )
            case 'hotels':
                return (
                    <IconContent bg="#D23F34" className="icon">
                        <FeatherIcon icon="monitor" size="20" stroke="#fff" />
                    </IconContent>
                )
            case 'totems':
                return (
                    <IconContent bg="#019265" className="icon">
                        <FeatherIcon icon="grid" size="20" stroke="#fff" />
                    </IconContent>
                )
            case 'operators':
                return (
                    <IconContent bg="#D91F56" className="icon">
                        <FeatherIcon icon="user" size="20" stroke="#fff" />
                    </IconContent>
                )
            case 'excursions':
                return (
                    <IconContent bg="#F69229" className="icon">
                        <FeatherIcon icon="map" size="20" stroke="#fff" />
                    </IconContent>
                )

            default:
                return (
                    <IconContent bg="#1B2732" className="icon">
                        <FeatherIcon icon="user" size="20" stroke="#fff" />
                    </IconContent>
                )
        }
    }

    const ReturnLabel = (index) => {
        switch (index) {
            case 'reservations':
                return (
                    <strong>
                        <small>Reservaciones</small>
                    </strong>
                )
            case 'hotels':
                return (
                    <strong>
                        <small>Hoteles</small>
                    </strong>
                )
            case 'totems':
                return (
                    <strong>
                        <small>Totems</small>
                    </strong>
                )
            case 'operators':
                return (
                    <strong>
                        <small>Operadores</small>
                    </strong>
                )
            case 'excursions':
                return (
                    <strong>
                        <small>Excursiones</small>
                    </strong>
                )

            default:
                return ''
        }
    }

    const ReturnColor = (index) => {
        switch (index) {
            case 'reservations':
                return '#415799'
            case 'hotels':
                return '#D23F34'
            case 'totems':
                return '#019265'
            case 'operators':
                return '#D91F56'
            case 'excursions':
                return '#F69229'

            default:
                return '#1B2732'
        }
    }

    return (
        <>
            <Row>
                <Col>
                    <H1>Resumen Punta Cana App</H1>
                </Col>
            </Row>
            <Row>
                {Object.keys(data).map((index, key) => (
                    <Col xs="6" md="4" key={key}>
                        <CardResume bg={ReturnColor(index)}>
                            {ReturnIcon(index)}
                            <span className="value">
                                {data[index]} {ReturnLabel(index)}
                            </span>
                        </CardResume>
                    </Col>
                ))}
            </Row>
        </>
    )
}

const CardResume = styled.div`
    background: linear-gradient(90deg, ${(props) => (props.bg ? props.bg : '#F00')} 10px, #fff 10px);
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
    padding: 15px 10px;
    border-radius: 3px;
    background-color: #fff;
    border: solid 1px #eee;
    .icon {
        display: block;
    }
    .value {
        font-size: 16px;
        font-weight: bold;
    }
`

const IconContent = styled.div`
    border-radius: 50%;
    padding: 10px;
    width: 46px;
    height: 46px;
    margin: 0 auto;
    margin-bottom: 10px;
    background-color: ${(props) => (props.bg ? props.bg : '#000')};
`

const H1 = styled.h1`
    font-size: 20px;
    font-weight: bold;
    color: #5abd91;
    margin-bottom: 20px;
    margin-top: 10px;
`
