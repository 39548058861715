import { BREADCRUMBS_COLLAPSED } from '@blueprintjs/core/lib/esm/common/classes'
import React from 'react'
import AsyncSelect from 'react-select/async'
import { FilterHotels } from '../../../API/Core'

export default function InputHotel(props) {
    const [debounce, setDebounce] = React.useState(null)
    const [defaultValue, setdefaultValue] = React.useState(false)

    const initData = async (inputValue) => {
        let response = await FilterHotels(`?search=${inputValue}&page_size=200`)
        let results = response.data.results
        let find = results.find((hotel) => hotel.uid === props.defaultUID)
        console.log(find)
        if (typeof find !== 'undefined') {
            setdefaultValue({ label: find.name, value: find.uid })
        } else {
            setdefaultValue(null)
        }
    }

    React.useEffect(() => {
        initData('')
    }, [])

    const loadOptions = (inputValue, callback) => {
        let debounce_ = debounce
        clearTimeout(debounce_)
        debounce_ = setTimeout(async () => {
            try {
                let response = await FilterHotels(`?search=${inputValue}&page_size=10`)
                let data = []
                let results = response.data.results
                for (let x = 0; x < results.length; x++) {
                    data.push({ label: results[x].name, value: results[x].uid })
                }
                return callback(data)
            } catch (error) {
                console.log(error)
                return callback(null)
            }
        }, 600)
        setDebounce(debounce_)
    }
    const handleOnchange = (e) => {
        if (e !== null) {
            props.onChange(e.value)
        } else {
            props.onChange(e)
        }
    }

    return (
        <div>
            {defaultValue !== false && (
                <AsyncSelect loadOptions={loadOptions} defaultOptions defaultValue={defaultValue !== null ? defaultValue : props.defaultValue} isClearable onChange={handleOnchange} />
            )}
        </div>
    )
}
