import React from 'react'
import { Row, Col, Container } from 'reactstrap'
import FeatherIcon from 'feather-icons-react'
import styled from 'styled-components'
import renderHTML from 'react-render-html'

export default function Description(props) {
    return (
        <Row>
            <Col md="12">
                <label className="form-group w-100">
                    <strong>Descripción (Español)</strong>
                    {props.excursion.description !== null && <P>{renderHTML(props.excursion.description)}</P>}
                </label>
            </Col>
            <Col md="12">
                <label className="form-group w-100">
                    <strong>Descripción (Inglés)</strong>
                    {props.excursion.description_en !== null && <P>{renderHTML(props.excursion.description_en)}</P>}
                </label>
            </Col>
            <Col md="12">
                <label className="form-group w-100">
                    <strong>Descripción (Alemán)</strong>
                    {props.excursion.description_gr !== null && <P>{renderHTML(props.excursion.description_gr)}</P>}
                </label>
            </Col>
            <Col md="12">
                <label className="form-group w-100">
                    <strong>Descripción (Frances)</strong>
                    {props.excursion.description_fr !== null && <P>{renderHTML(props.excursion.description_fr)}</P>}
                </label>
            </Col>
            <Col md="12">
                <label className="form-group w-100">
                    <strong>Descripción (Ruso)</strong>
                    {props.excursion.description_ru !== null && <P>{renderHTML(props.excursion.description_ru)}</P>}
                </label>
            </Col>
        </Row>
    )
}

const P = styled.p`
    border: solid 1px #eee;
    padding: 10px;
    max-height: 300px;
    overflow-y: auto;
`
