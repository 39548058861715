import React from 'react'
import styled from 'styled-components'
import { Row, Col, Container } from 'reactstrap'
import Logo from '../Components/Sidebar/logo'
import Links from '../Components/Sidebar/links'
import Logout from '../Components/Sidebar/logout'

export default function Sidebar(props) {
    return (
        <ContentSidebar {...props}>
            <Row>
                <Col>
                    <Logo width="90%" />
                </Col>
            </Row>
            <Row style={{ marginTop: '40px' }}>
                <Col>
                    <Links />
                </Col>
            </Row>
        </ContentSidebar>
    )
}

const ContentSidebar = styled.div`
    min-height: 100vh;
    background-color: #fff;
    box-shadow: 3px 0 10px #ddd;
`
