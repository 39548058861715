import { API } from './main'

export function FilterCategories(qs) {
    return API(`/excursions/categories/${qs}`)
}

export function DeleteCategory(id) {
    return API.delete(`/excursions/p/categories/${id}/`)
}

export function UpdateCategory(id, data) {
    return API.patch(`/excursions/p/categories/${id}/`, data)
}

export function GetCategory(id) {
    return API(`/excursions/p/categories/${id}/`)
}

export function CreateCategory(data) {
    return API.post(`/excursions/p/categories/`, data)
}

export function FilterCategoriesDashboard(qs) {
    return API(`/excursions/p/categories/${qs}`)
}

export function FilterExcursions(qs) {
    return API(`/excursions/p/${qs}`)
}

export function GetExcursion(uid) {
    return API(`/excursions/p/${uid}/`)
}

export function CreateExcursion(data) {
    return API.post(`/excursions/p/`, data)
}

export function UpdateExcursion(uid, data) {
    return API.patch(`/excursions/p/${uid}/`, data)
}

export function DeleteExcursion(uid) {
    return API.delete(`/excursions/p/${uid}/`)
}

export function DuplicateExcursion(uid) {
    return API(`/excursions/p/${uid}/duplicate/`)
}

export function DeleteLockedDate(uid, data) {
    return API.post(`/excursions/p/${uid}/unlock_date/`, data)
}

export function CreateLockedDate(uid, data) {
    return API.post(`/excursions/p/${uid}/lock_date/`, data)
}
