import React from 'react'
import styled from 'styled-components'
import FeatherIcon from 'feather-icons-react'
import { Link } from 'react-router-dom'

export default function LinksMobile(props) {
    return (
        <Ul>
            <Li>
                <Link to="/excursiones">
                    <LinkStyled>
                        <FeatherIcon icon="map" size="14" stroke="#444" /> Excursiones
                    </LinkStyled>
                </Link>
            </Li>
            <Li>
                <Link to="/reservaciones">
                    <LinkStyled>
                        <FeatherIcon icon="monitor" size="14" stroke="#444" /> Reservaciones
                    </LinkStyled>
                </Link>
            </Li>
            <Li>
                <Link to="/hoteles">
                    <LinkStyled>
                        <FeatherIcon icon="monitor" size="14" stroke="#444" /> Hoteles
                    </LinkStyled>
                </Link>
            </Li>
            <Li>
                <Link to="/totems">
                    <LinkStyled>
                        <FeatherIcon icon="grid" size="14" stroke="#444" /> Totems
                    </LinkStyled>
                </Link>
            </Li>
            {/* <LiHelp>
                <LinkStyled href="https://serviarquitectura.com" target="_blank">
                    <FeatherIcon icon="help-circle" size="25" stroke="#666" />
                </LinkStyled>
            </LiHelp> */}
        </Ul>
    )
}

const Li = styled.li`
    position: relative;
    float: left;
    text-align: center;
`

const LiHelp = styled.li`
    position: relative;
    float: right;
    text-align: center;
`

const LinkStyled = styled.a`
    cursor: pointer;
    width: 100%;
    height: 100%;
    padding: 10px 5px;
    position: relative;
    float: left;
    color: #666;
`

const Ul = styled.ul`
    width: 100%;
    position: relative;
    padding: 0;
    text-align: center;
`
