import React from 'react'
import { Modal, ModalHeader } from 'reactstrap'
import { CreateCategory } from '../../API/Excursions'
import Form from '../Form/Form'
import { FIELDS_CATEGORIES } from '../../helpers/fields'

export default function ModalCreateCategory(props) {
    return (
        <div>
            <Modal isOpen={props.open} toggle={props.onClose}>
                <ModalHeader toggle={props.onClose}>Nueva Categoría</ModalHeader>
                <Form SendData={CreateCategory} refreshList={props.refreshList} onClose={props.onClose} fields={FIELDS_CATEGORIES} />
            </Modal>
        </div>
    )
}
