import React from 'react'
import { Row, Col, Container } from 'reactstrap'
import FeatherIcon from 'feather-icons-react'
import styled from 'styled-components'
import NumberInput from 'react-number-format'
export default function Price(props) {
    return (
        <Row>
            <Col md="4">
                <label className="form-group w-100">
                    <strong>Precio</strong>
                    <NumberInput
                        onValueChange={(e) => {
                            props.onChange('price', e.floatValue)
                        }}
                        id="price"
                        value={props.excursion.price}
                        thousandSeparator=","
                        prefix="$ "
                        className="form-control"
                    />
                </label>
            </Col>
            <Col md="4">
                <label className="form-group w-100">
                    <strong>Precio (Adultos)</strong>
                    <NumberInput
                        onValueChange={(e) => {
                            props.onChange('adult_price', e.floatValue)
                        }}
                        id="adult_price"
                        value={props.excursion.adult_price}
                        thousandSeparator=","
                        prefix="$ "
                        className="form-control"
                    />
                </label>
            </Col>
            <Col md="4">
                <label className="form-group w-100">
                    <strong>Precio (Niños)</strong>
                    <NumberInput
                        onValueChange={(e) => {
                            props.onChange('kids_price', e.floatValue)
                        }}
                        id="kids_price"
                        value={props.excursion.kids_price}
                        thousandSeparator=","
                        prefix="$ "
                        className="form-control"
                    />
                </label>
            </Col>
        </Row>
    )
}
