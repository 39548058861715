import React from 'react'
import { Row, Col, Container } from 'reactstrap'
import FeatherIcon from 'feather-icons-react'
import styled from 'styled-components'

export default function Config(props) {
    return (
        <Row>
            <Col md="4" sm="4" xl="4">
                <div className="w-100">
                    <p>
                        <label>
                            <strong>Estado</strong>
                        </label>
                        <p>{props.excursion.is_active ? 'Activo' : 'Inactivo'}</p>
                    </p>
                    <p>
                        <label>
                            <strong>Permitir compra</strong>
                        </label>
                        <p>{props.excursion.can_purchase ? 'Si' : 'No'}</p>
                    </p>
                </div>
            </Col>
            <Col md="4" sm="4" xl="4">
                <div className="w-100">
                    <p>
                        <label>
                            <strong>Destacada</strong>
                        </label>
                        <p>{props.excursion.is_featured ? 'Si' : 'No'}</p>
                    </p>
                    <p>
                        <label>
                            <strong>Requiere habitación</strong>
                        </label>
                        <p>{props.excursion.require_room ? 'Si' : 'No'}</p>
                    </p>
                </div>
            </Col>
            <Col md="4" sm="4" xl="4">
                <div className="w-100">
                    <p>
                        <label>
                            <strong>Solo reservación</strong>
                        </label>
                        <p>{props.excursion.only_reservation ? 'Si' : 'No'}</p>
                    </p>
                </div>
            </Col>
        </Row>
    )
}
