import React from 'react'
import { Modal, ModalHeader } from 'reactstrap'
import { CreateExcursion } from '../../API/Excursions'
import Form from '../Form/Form'
import { FIELDS_EXCURSIONS } from '../../helpers/fields'

export default function ModalCreateTotem(props) {
    return (
        <div>
            <Modal isOpen={props.open} toggle={props.onClose}>
                <ModalHeader toggle={props.onClose}>Nueva Excursión</ModalHeader>
                <Form SendData={CreateExcursion} refreshList={props.refreshList} onClose={props.onClose} fields={FIELDS_EXCURSIONS} />
            </Modal>
        </div>
    )
}
