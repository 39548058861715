import React from 'react'
import styled from 'styled-components'
import { Row, Col, Container } from 'reactstrap'

export default function Footer(props) {
    return (
        <FooterContent>
            <Container fluid>
                <Row>
                    <Col>© Todos los derechos reservados, PuntaCanaApp 2021</Col>
                </Row>
            </Container>
        </FooterContent>
    )
}

const FooterContent = styled.div`
    width: 100%;
    text-align: right;
    color: #999;
    background: #eee;
    font-size: 12px;
    height: 3vh;
`
