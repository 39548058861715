import React from 'react'
import { Modal, ModalHeader } from 'reactstrap'
import { CreateOperator } from '../../API/Members'
import Form from '../Form/Form'
import { FIELDS_OPERATORS } from '../../helpers/fields'

export default function ModalCreateOperator(props) {
    return (
        <div>
            <Modal isOpen={props.open} toggle={props.onClose}>
                <ModalHeader toggle={props.onClose}>Nuevo Operador</ModalHeader>
                <Form SendData={CreateOperator} refreshList={props.refreshList} onClose={props.onClose} fields={FIELDS_OPERATORS} />
            </Modal>
        </div>
    )
}
