export const FIELDS_TOTEMS = [
    { required: true, label: 'Nombre de inicio de sesión', id: 'username', type: 'text', info: 'Nombre del totem que se utilizará para inciar sesión en la aplicación.' },
    { required: true, label: 'Contraseña', id: 'password', type: 'text' },
    { required: true, label: 'Hotel', id: 'hotel', type: 'hotel' },
]

export const FIELDS_EXCURSIONS = [
    { required: true, label: 'Nombre', id: 'name', type: 'text' },
    { required: true, label: 'Hotel', id: 'hotel', type: 'hotel' },
    { required: true, label: 'Categoría', id: 'category', type: 'category' },
    { required: true, label: 'Operador', id: 'operated_by', type: 'operator' },
    { required: false, label: 'Imagen principal', id: 'featured_image', type: 'file' },
    { required: true, label: 'Tipo de excursion', id: 'excursion_type', type: 'excursion_type' },
]

export const FIELDS_OPERATORS = [
    { required: true, label: 'Nombre', id: 'name', type: 'text' },
    { required: true, label: 'Correo de contacto', id: 'email', type: 'email' },
    { required: false, label: 'Teléfono 1', id: 'phone1', type: 'phone' },
    { required: false, label: 'Teléfono 2', id: 'phone2', type: 'phone' },
    { required: false, label: 'Sitio web', id: 'website', type: 'text' },
    { required: false, label: 'Logo', id: 'logo', type: 'file' },
    { required: false, label: 'Descripción', id: 'description', type: 'textarea' },
]

export const FIELDS_HOTEL = [
    { required: true, label: 'Nombre del hotel', id: 'name', type: 'text' },
    { required: true, label: 'Teléfono', id: 'phone', type: 'phone' },
    { required: false, label: 'Dirrección 1', id: 'address1', type: 'text' },
    { required: false, label: 'Dirección 2', id: 'address2', type: 'text' },
    { required: true, label: 'Correo de contacto', id: 'email', type: 'email' },
    // { required:true, label: 'Hotel', id: 'color', type: 'hotel' },
    // { required:true, label: 'Hotel', id: 'color_alt', type: 'hotel' },
    { required: true, label: 'Logo', id: 'logo', type: 'file' },
    { required: false, label: 'Logo Alternativo', id: 'logo_alt', type: 'file' },
    { required: false, label: 'favicon', id: 'favicon', type: 'file' },
    // { required:true, label: 'Hotel', id: 'is_active', type: 'hotel' },
    { required: false, label: 'Facebook#', id: 'facebook', type: 'text' },
    { required: false, label: 'Instagram#', id: 'instagram', type: 'text' },
    { required: false, label: 'Twitter#', id: 'twitter', type: 'text' },
    { required: false, label: 'Lista de correos', id: 'email_notification', type: 'textarea', info: 'Lista de correos donde se notificará cuando se realice una reservación.' },
]

export const FIELDS_CATEGORIES = [
    { required: true, label: 'Nombre de la categoría', id: 'name', type: 'text' },
    { required: true, label: 'Imagen destacada', id: 'featured_image', type: 'file' },
]
