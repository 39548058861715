import React from 'react'
import Select from 'react-select'

const EXCURSION_TYPE = [
    { value: 1, label: 'Excursion' },
    { value: 2, label: 'Hotel' },
    { value: 6, label: 'Por asiento' },
    { value: 3, label: 'Restaurant' },
    { value: 4, label: 'General' },
    { value: 5, label: 'Eventos' },
]

export default function InputTypeExcursion(props) {
    const handleOnchange = (e) => {
        if (e !== null) {
            props.onChange(e.value)
        } else {
            props.onChange(e)
        }
    }

    return (
        <div>
            <Select options={EXCURSION_TYPE} defaultValue={props.defaultValue} isClearable onChange={handleOnchange} />
        </div>
    )
}
