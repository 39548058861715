import React from 'react'
import { Row, Col, Container } from 'reactstrap'
import FeatherIcon from 'feather-icons-react'
import styled from 'styled-components'

import { Popover2 } from '@blueprintjs/popover2'
import { InputCategory, InputHotel } from './Form/Fields'
import { Switch } from '@blueprintjs/core/'

export default function Filters(props) {
    const FiltersContainer = () => {
        return (
            <ContainerF>
                <Row>
                    <Col>
                        <label className="w-100">
                            Categoría
                            <InputCategory defaultID={props.filters.category} onChange={(value) => props.onChange('category', value)} />
                        </label>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <label className="w-100">
                            Hotel
                            <InputHotel defaultUID={props.filters.hotel} onChange={(value) => props.onChange('hotel', value)} />
                        </label>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <label className="w-100">
                            Estado
                            <select
                                value={typeof props.filters.is_active !== 'undefined' ? props.filters.is_active : ''}
                                className="form-control"
                                onChange={(e) => props.onChange('is_active', e.target.value)}>
                                <option value="">Todas</option>
                                <option value="1">Activas</option>
                                <option value="0">Inactivas</option>
                            </select>
                        </label>
                    </Col>
                </Row>
            </ContainerF>
        )
    }

    return (
        <div>
            <Popover2 portalClassName="foo" enforceFocus={false} content={FiltersContainer()}>
                <ButtonFilter>
                    <FeatherIcon icon="filter" size="17" stroke="#999" />
                </ButtonFilter>
            </Popover2>
        </div>
    )
}

const ContainerF = styled.div`
    padding: 15px 10px;
    min-width: 300px;
`

const ButtonFilter = styled.button`
    background-color: #fff;
    border: none;
    box-shadow: none;
    outline: none;
`
