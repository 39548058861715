import React from 'react'
import styled from 'styled-components'
import { Row, Col, Container } from 'reactstrap'
import FeatherIcon from 'feather-icons-react'
import { GetExcursion, UpdateExcursion, DeleteLockedDate, CreateLockedDate } from '../../API/Excursions'
import { NameForm, ConfigForm, PriceForm, MediaForm, DescriptionForm, DetailForm, NameValue, ConfigValue, PriceValue, MediaValue, DescriptionValue, DetailValue, Actions } from './DetailBlocks/'
import Toast from '../../Components/Toast'
import DayPicker, { DateUtils } from 'react-day-picker'
import 'react-day-picker/lib/style.css'
import { Spinner } from '@blueprintjs/core'
import moment from 'moment'
export default function Detail(props) {
    const [openTab, setOpenTab] = React.useState([true, true, true, true, true, true, true])
    const [editTab, setEditTab] = React.useState([false, false, false, false, false, false])
    const [loading, setLoading] = React.useState([false, false, false, false, false, false])
    const [data, setData] = React.useState(null)
    const [lockedDates, setLockedDates] = React.useState([])
    const uid = props.match.params.id
    const handleTab = (e) => {
        let index = e.target.getAttribute('data-tab')
        let openTab_ = Array.from(openTab)
        openTab_[index] = !openTab_[index]
        setOpenTab(openTab_)
    }

    const initData = async () => {
        let response = await GetExcursion(uid)

        let locked_dates_ = response.data.locked_dates
        let arr = []
        for (let x = 0; x < locked_dates_.length; x++) {
            arr.push(moment(locked_dates_[x]).toDate())
        }

        setLockedDates(arr)
        setData(response.data)
    }

    const handleSwitch = (e) => {
        let index = e.target.getAttribute('id')
        let data_ = Object.assign({}, data)
        data_[index] = e.target.checked
        setData(data_)
    }

    const handleFields = (e) => {
        let index = e.target.getAttribute('id')
        let data_ = Object.assign({}, data)
        data_[index] = e.target.value
        setData(data_)
    }

    const handleAdvancedFields = (index, value) => {
        let data_ = Object.assign({}, data)
        console.log(index, value)
        data_[index] = value
        setData(data_)
    }

    const handleFiles = (e) => {
        let index = e.target.getAttribute('id')
        let data_ = Object.assign({}, data)
        data_[index] = e.target.files[0]
        setData(data_)
    }

    const handleEdit = (e) => {
        let index = e.target.getAttribute('data-index')
        let editTab_ = Array.from(editTab)
        editTab_[index] = !editTab_[index]
        setEditTab(editTab_)
    }

    const saveData = async (e) => {
        let index = e.target.getAttribute('data-index')
        let loading_ = Array.from(loading)
        let data_ = Object.assign({}, data)

        loading_[index] = true
        setLoading(loading_)

        data_.hotel = typeof data_.hotel === 'object' ? data_.hotel.value : data_.hotel
        data_.excursion_type = typeof data_.excursion_type === 'object' ? data_.excursion_type.value : data_.excursion_type
        data_.category = typeof data_.category === 'object' ? data_.category.value : data_.category
        data_.operated_by = typeof data_.operated_by === 'object' ? data_.operated_by.value : data_.operated_by
        data_.price = isNaN(data_.price) || data_.price === null ? 0 : data_.price
        data_.kids_price = isNaN(data_.kids_price) || data_.kids_price === null ? 0 : data_.kids_price
        data_.adult_price = isNaN(data_.adult_price) || data_.adult_price === null ? 0 : data_.adult_price

        if (typeof data_.itinerary !== 'object') delete data_['itinerary']
        if (typeof data_.featured_image !== 'object') delete data_['featured_image']
        if (typeof data_.big_image !== 'object') delete data_['big_image']

        let form = new FormData()
        for (let x in data_) {
            if (data_[x] === null || data_[x] === 'null') data_[x] = ''
            form.append(x, data_[x])
        }

        try {
            await UpdateExcursion(uid, form)
            initData()

            Toast('success', 'Registro actualizado exitosamente!')
        } catch (err) {
            console.log(err)
            Toast('danger', 'Ha ocurrido un error!')
        }
        let editTab_ = Array.from(editTab)
        editTab_[index] = !editTab_[index]
        loading_[index] = false
        setEditTab(editTab_)
        setLoading(loading_)
    }

    const handleDayClick = async (day, { selected }) => {
        let lockedDates_ = Array.from(lockedDates)
        if (selected) {
            const selectedIndex = lockedDates_.findIndex((selectedDay) => DateUtils.isSameDay(selectedDay, day))
            lockedDates_.splice(selectedIndex, 1)
            try {
                await DeleteLockedDate(uid, { date: moment(day).format('YYYY-MM-DD') })
                Toast('success', 'Fecha desbloqueada exitosamente!')
            } catch (err) {
                console.log(err)
                Toast('danger', 'Ha ocurrido un error!')
            }
        } else {
            lockedDates_.push(day)
            try {
                await CreateLockedDate(uid, { date: moment(day).format('YYYY-MM-DD') })
                Toast('success', 'Fecha bloqueada exitosamente!')
            } catch (err) {
                console.log(err)
                Toast('danger', 'Ha ocurrido un error!')
            }
        }
        setLockedDates(lockedDates_)
    }

    React.useEffect(() => {
        initData()
    }, [])

    if (data !== null) {
        return (
            <Container fluid>
                <DivLoading loading={loading[0]}>
                    <Row>
                        <Col>
                            <Title>
                                <h2 data-tab="0" onClick={handleTab}>
                                    Nombre de la excursión <FeatherIcon icon={openTab[0] ? 'chevron-down' : 'chevron-up'} size="14" />
                                </h2>
                            </Title>
                        </Col>
                        <Col xs="12">
                            <Div>
                                {loading[0] && <Spinner size="16" />}
                                <Actions saveData={saveData} handleEdit={handleEdit} dataIndex="0" editTab={editTab[0]} className="btn-edit" />
                                {openTab[0] && <>{!editTab[0] ? <NameValue excursion={data} /> : <NameForm onChange={handleFields} excursion={data} />}</>}
                            </Div>
                        </Col>
                    </Row>
                </DivLoading>

                <DivLoading loading={loading[1]}>
                    <Row>
                        <Col className="mt-4">
                            <Title>
                                <h2 data-tab="1" onClick={handleTab}>
                                    Precios de la excursión <FeatherIcon icon={openTab[1] ? 'chevron-down' : 'chevron-up'} size="14" />
                                </h2>
                            </Title>
                        </Col>
                        <Col xs="12">
                            <Div>
                                {loading[1] && <Spinner size="16" />}
                                <Actions saveData={saveData} handleEdit={handleEdit} dataIndex="1" editTab={editTab[1]} className="btn-edit" />
                                {openTab[1] && <>{!editTab[1] ? <PriceValue excursion={data} /> : <PriceForm onChange={handleAdvancedFields} excursion={data} />}</>}
                            </Div>
                        </Col>
                    </Row>
                </DivLoading>

                <DivLoading loading={loading[2]}>
                    <Row>
                        <Col className="mt-4">
                            <Title>
                                <h2 data-tab="2" onClick={handleTab}>
                                    Detalles de la excursión <FeatherIcon icon={openTab[2] ? 'chevron-down' : 'chevron-up'} size="14" />
                                </h2>
                            </Title>
                        </Col>
                        <Col xs="12">
                            <Div>
                                {loading[2] && <Spinner size="16" />}
                                <Actions saveData={saveData} handleEdit={handleEdit} dataIndex="2" editTab={editTab[2]} className="btn-edit" />
                                {openTab[2] && <>{!editTab[2] ? <DetailValue excursion={data} /> : <DetailForm onChange={handleAdvancedFields} excursion={data} />}</>}
                            </Div>
                        </Col>
                    </Row>
                </DivLoading>

                <DivLoading loading={loading[3]}>
                    <Row>
                        <Col className="mt-4">
                            <Title>
                                <h2 data-tab="3" onClick={handleTab}>
                                    Configuración de la excursión <FeatherIcon icon={openTab[3] ? 'chevron-down' : 'chevron-up'} size="14" />
                                </h2>
                            </Title>
                        </Col>
                        <Col xs="12">
                            <Div>
                                {loading[3] && <Spinner size="16" />}
                                <Actions saveData={saveData} handleEdit={handleEdit} dataIndex="3" editTab={editTab[3]} className="btn-edit" />
                                {openTab[3] && <>{!editTab[3] ? <ConfigValue excursion={data} /> : <ConfigForm onChange={handleSwitch} excursion={data} />}</>}
                            </Div>
                        </Col>
                    </Row>
                </DivLoading>

                <DivLoading loading={loading[4]}>
                    <Row>
                        <Col className="mt-4">
                            <Title>
                                <h2 data-tab="4" onClick={handleTab}>
                                    Descripción de la excursión <FeatherIcon icon={openTab[4] ? 'chevron-down' : 'chevron-up'} size="14" />
                                </h2>
                            </Title>
                        </Col>
                        <Col xs="12">
                            <Div>
                                {loading[4] && <Spinner size="16" />}
                                <Actions saveData={saveData} handleEdit={handleEdit} dataIndex="4" editTab={editTab[4]} className="btn-edit" />

                                {openTab[4] && (
                                    <>{!editTab[4] ? <DescriptionValue excursion={data} /> : <DescriptionForm onChange={handleAdvancedFields} onChangeFields={handleFields} excursion={data} />}</>
                                )}
                            </Div>
                        </Col>
                    </Row>
                </DivLoading>

                <DivLoading loading={loading[5]}>
                    <Row>
                        <Col className="mt-4">
                            <Title>
                                <h2 data-tab="5" onClick={handleTab}>
                                    Multimedia <FeatherIcon icon={openTab[5] ? 'chevron-down' : 'chevron-up'} size="14" />
                                </h2>
                            </Title>
                        </Col>
                        <Col xs="12">
                            <Div>
                                {loading[5] && <Spinner size="16" />}
                                <Actions saveData={saveData} handleEdit={handleEdit} dataIndex="5" editTab={editTab[5]} className="btn-edit" />
                                {openTab[5] && <>{!editTab[5] ? <MediaValue excursion={data} /> : <MediaForm onChange={handleFields} handleFiles={handleFiles} excursion={data} />}</>}
                            </Div>
                        </Col>
                    </Row>
                </DivLoading>

                <Row>
                    <Col className="mt-4">
                        <Title>
                            <h2 data-tab="6" onClick={handleTab}>
                                Bloquear Fechas <FeatherIcon icon={openTab[5] ? 'chevron-down' : 'chevron-up'} size="14" />
                            </h2>
                        </Title>
                    </Col>
                </Row>
                <Div>
                    {openTab[6] && (
                        <>
                            {' '}
                            <small>Haz click en la fecha que deseas bloquear/desbloquear.</small>
                            <br />
                            <DayPicker selectedDays={lockedDates} onDayClick={handleDayClick} />
                            <p>
                                <br />
                                <small>
                                    <strong style={{ color: '#c00' }}>Nota:</strong> Se recomienda que una vez realizados los cambios te dirijas al listado de <strong>"Hoteles"</strong> y reinicies
                                    los totems para que se vean reflejados los cambios realizados.
                                </small>
                            </p>
                        </>
                    )}
                </Div>
            </Container>
        )
    } else {
        return <div></div>
    }
}

const Div = styled.div`
    width: 100%;
    position: relative;
    :hover .btn-edit {
        display: block;
    }
`

const Title = styled.div`
    position: relative;
    width: 100%;
    height: 20px;
    z-index: 0;

    svg {
        pointer-events: none;
    }
    h2 {
        cursor: pointer;
        padding-right: 10px;
        position: relative;
        float: left;
        left: 0;
        font-weight: bold;
        color: #57bc8f;
        font-size: 16px;
        background-color: #fff;
        z-index: 2;
    }
    :before {
        content: '';
        position: absolute;
        width: 100%;
        float: right;
        top: 50%;
        height: 1px;
        z-index: 1;
        background: #ccc;
    }
`

const DivLoading = styled.div`
    transition: all 0.3s ease;
    width: 100%;
    opacity: ${(props) => (props.loading ? 0.6 : 1)};
    pointer-events: ${(props) => (props.loading ? 'none' : 'inherit')};
`
