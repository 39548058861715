import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'reactstrap'

export default function NotFound(props) {
    return (
        <Row>
            <Col>
                <Div>
                    <img src="https://servi.s3.us-east-2.amazonaws.com/static/404.png" alt="not-found-404-error" />
                </Div>
            </Col>
        </Row>
    )
}

const Div = styled.div`
    width: 100%;
    padding-top: 40px;
    text-align: center;
    img {
        max-width: 100%;
    }
`
