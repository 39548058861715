import React from 'react'
import AsyncSelect from 'react-select/async'
import { FilterCategoriesDashboard } from '../../../API/Excursions'
import styled from 'styled-components'

export default function InputCategory(props) {
    const [debounce, setDebounce] = React.useState(null)
    const [defaultValue, setdefaultValue] = React.useState(false)

    const initData = async (inputValue) => {
        let response = await FilterCategoriesDashboard(`?search=${inputValue}&page_size=200`)
        let results = response.data.results
        let find = results.find((category) => category.id === props.defaultID)
        console.log(find)
        if (typeof find !== 'undefined') {
            setdefaultValue({ label: find.name, value: find.id })
        } else {
            setdefaultValue(null)
        }
    }

    React.useEffect(() => {
        initData('')
    }, [])

    const loadOptions = (inputValue, callback) => {
        let debounce_ = debounce
        clearTimeout(debounce_)
        debounce_ = setTimeout(async () => {
            try {
                let response = await FilterCategoriesDashboard(`?search=${inputValue}&page_size=10`)
                let data = []
                let results = response.data.results
                for (let x = 0; x < results.length; x++) {
                    data.push({ label: results[x].name.toLowerCase(), value: results[x].id })
                }
                return callback(data)
            } catch (error) {
                console.log(error)
                return callback(null)
            }
        }, 600)
        setDebounce(debounce_)
    }
    const handleOnchange = (e) => {
        if (e !== null) {
            props.onChange(e.value)
        } else {
            props.onChange(e)
        }
    }
    return (
        <Div>
            {defaultValue !== false && (
                <AsyncSelect loadOptions={loadOptions} defaultValue={defaultValue !== null ? defaultValue : props.defaultValue} defaultOptions isClearable onChange={handleOnchange} />
            )}
        </Div>
    )
}

const Div = styled.div`
    * {
        text-transform: capitalize;
    }
`
