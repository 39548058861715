import React from 'react'
import styled from 'styled-components'
import FeatherIcon from 'feather-icons-react'
import { Link } from 'react-router-dom'
import Logout from './logout'
export default function Links(props) {
    return (
        <Ul>
            <Li>
                <Link to="/">
                    <LinkStyled>
                        <FeatherIcon icon="home" size="14" stroke="#444" /> Inicio
                    </LinkStyled>
                </Link>
            </Li>
            <Li>
                <Link to="/excursiones">
                    <LinkStyled>
                        <FeatherIcon icon="map" size="14" stroke="#444" /> Excursiones
                    </LinkStyled>
                </Link>
            </Li>
            <Li>
                <Link to="/categorias">
                    <LinkStyled>
                        <FeatherIcon icon="pause" size="14" stroke="#444" /> Categorías
                    </LinkStyled>
                </Link>
            </Li>
            <Li>
                <Link to="/reservaciones">
                    <LinkStyled>
                        <FeatherIcon icon="file-text" size="14" stroke="#444" /> Reservaciones
                    </LinkStyled>
                </Link>
            </Li>
            <Li>
                <Link to="/hoteles">
                    <LinkStyled>
                        <FeatherIcon icon="monitor" size="14" stroke="#444" /> Hoteles
                    </LinkStyled>
                </Link>
            </Li>
            <Li>
                <Link to="/totems">
                    <LinkStyled>
                        <FeatherIcon icon="grid" size="14" stroke="#444" /> Totems
                    </LinkStyled>
                </Link>
            </Li>
            <Li>
                <Link to="/operadores">
                    <LinkStyled>
                        <FeatherIcon icon="user" size="14" stroke="#444" /> Operadores
                    </LinkStyled>
                </Link>
            </Li>
            <Logout />
            {/* <LiHelp>
                <Link to="/excursiones">
                    <LinkStyled>
                        <FeatherIcon icon="help-circle" size="25" stroke="#444" />
                    </LinkStyled>
                </Link>
            </LiHelp> */}
        </Ul>
    )
}

const Li = styled.li`
    width: 100%;
    position: relative;
    float: left;
    text-align: left;
`

const LiHelp = styled.li`
    top: 90%;
    width: 100%;
    position: relative;
    float: left;
    text-align: center;
`

const LinkStyled = styled.a`
    cursor: pointer;
    width: 100%;
    height: 100%;
    padding-top: 9px;
    padding-bottom: 9px;
    padding-left: 25px;
    position: relative;
    float: left;
    color: #444;
    text-decoration: none;
    font-size: 14px;
    text-decoration: none !important;
    svg {
        margin-right: 10px;
    }
`

const Ul = styled.ul`
    margin-top: 30px;
    height: 80vh;
    width: 100%;
    position: relative;
    padding: 0;
    text-align: center;
    li {
        a {
            transition: all 0.3s ease;
            color: #666;
        }
        transition: all 0.3s ease;
        :hover {
            background-color: #8beea7;
        }
    }
`
