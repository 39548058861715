import React from 'react'
import { Row, Col, Container } from 'reactstrap'
import FeatherIcon from 'feather-icons-react'
import styled from 'styled-components'
import NumberInput from 'react-number-format'

export default function Price(props) {
    return (
        <Row>
            <Col md="4">
                <label className="form-group w-100">
                    <strong>Precio</strong>
                    <p>
                        <NumberInput id="price" value={props.excursion.price} thousandSeparator="," displayType="text" prefix="$ " />
                    </p>
                </label>
            </Col>
            <Col md="4">
                <label className="form-group w-100">
                    <strong>Precio (Adultos)</strong>
                    <p>
                        <NumberInput id="price" value={props.excursion.adult_price} thousandSeparator="," displayType="text" prefix="$ " />
                    </p>
                </label>
            </Col>
            <Col md="4">
                <label className="form-group w-100">
                    <strong>Precio (Niños)</strong>
                    <p>
                        <NumberInput id="price" value={props.excursion.kids_price} thousandSeparator="," displayType="text" prefix="$ " />
                    </p>
                </label>
            </Col>
        </Row>
    )
}
