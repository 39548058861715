import React from 'react'
import styled from 'styled-components'
import { Row, Col, Container } from 'reactstrap'
import Table from '../../Components/Tables/Table'
import DebounceInput from '../../Components/Form/Fields/DebounceInput'
import Filters from '../../Components/filters'
import { DeleteExcursion, FilterExcursions, DuplicateExcursion } from '../../API/Excursions'
import ModalCreateExcursion from '../../Components/Modals/ModalCreateExcursion'
import { Divider } from '@blueprintjs/core'

const EXCURSIONS_HEADERS = ['Código', 'Nombre', 'Hotel', 'Categoría', 'Tipo', 'Operador', 'Fecha creación']

export default function Card(props) {
    const [excursions, setExcursions] = React.useState([])
    const [openModal, setOpenModal] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [count, setCount] = React.useState(0)
    const [current, setCurrent] = React.useState(1)
    const [filters, setFilters] = React.useState({ page_size: 15, page: 1 })

    const paramsURL = (filters) => {
        let params = ''
        console.log(filters)
        for (let x in filters) {
            params += `${x}=${filters[x]}&`
        }
        console.log(params)
        return params
    }

    const InitData = async (page, incomingFilters) => {
        setLoading(true)
        let filters_ = typeof incomingFilters !== 'undefined' ? incomingFilters : Object.assign({}, filters)
        filters_.page = page
        let params = paramsURL(filters_)
        let response = await FilterExcursions(`?${params}`)
        setCurrent(page)
        setFilters(filters_)
        setExcursions(response.data.results)
        setCount(response.data.count)
        setLoading(false)
    }

    const onChangeFilters = (index, value) => {
        let filters_ = Object.assign({}, filters)
        if (value !== null && value !== '') {
            filters_[index] = value
        } else {
            delete filters_[index]
        }
        console.log(filters_)

        InitData(1, filters_)
    }

    React.useEffect(() => {
        InitData(1)
    }, [])

    return (
        <Container fluid>
            <Row>
                <Col>
                    <Title>Listado de Excursiones</Title>
                </Col>
            </Row>
            <Row>
                <Col>
                    <ButtonNew
                        onClick={() => {
                            setOpenModal(true)
                        }}>
                        Agregar
                    </ButtonNew>
                    <ModalCreateExcursion refreshList={() => InitData(1)} open={openModal} onClose={() => setOpenModal(false)} />
                </Col>
                <Col xs="12" md="4">
                    <ContainerFilter width="calc(100% - 50px)">
                        <DebounceInput
                            placeholder="Buscar..."
                            onChange={(value) => {
                                onChangeFilters('search', value)
                            }}
                            className="form-control"
                        />
                    </ContainerFilter>
                    <ContainerFilter width="50px">
                        <Filters filters={filters} onChange={onChangeFilters} />
                    </ContainerFilter>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Table
                        loading={loading}
                        data={excursions}
                        count={count}
                        current={current}
                        setCurrent={setCurrent}
                        path={'excursiones'}
                        changePage={InitData}
                        headers={EXCURSIONS_HEADERS}
                        withActions
                        canDelete
                        canDuplicate
                        deleteItem={async (uid) => {
                            await DeleteExcursion(uid)
                            InitData(1)
                        }}
                        duplicateItem={async (uid) => {
                            await DuplicateExcursion(uid)
                            InitData(1)
                        }}
                    />
                </Col>
            </Row>
        </Container>
    )
}

const Title = styled.h1`
    font-size: 20px;
    margin-bottom: 10px;
    color: #51ba8a;
    font-weight: bold;
`

const ButtonNew = styled.button`
    background-color: #129544;
    border-radius: 3px;
    border: none;
    transition: all 0.3s ease;
    outline: none;
    box-shadow: none;
    color: #fff;
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    svg {
        transition: all 0.3s ease;
    }
    :hover {
        opacity: 0.9;
    }
`

const ContainerFilter = styled.div`
    width: ${(props) => (props.width ? props.width : '100%')};
    display: inline-block;
`
