import { API } from './main'

export function FilterHotels(qs) {
    return API(`p/hotels/${qs}`)
}

export function FilterTotems(qs) {
    return API(`p/totems/${qs}`)
}

export function GetHotel(uid) {
    return API(`p/hotels/${uid}/`)
}

export function GetTotem(uid) {
    return API(`p/totems/${uid}/`)
}

export function DeleteTotem(uid) {
    return API.delete(`p/totems/${uid}/`)
}

export function CreateTotem(data) {
    return API.post(`p/totems/`, data)
}

export function CreateHotel(data) {
    return API.post(`p/hotels/`, data)
}

export function DeleteHotel(uid) {
    return API.delete(`p/hotels/${uid}/`)
}

export function ReloadTotems(uid) {
    return API(`p/hotels/${uid}/refresh_all/`)
}

export function UpdateHotel(uid, data) {
    return API.patch(`p/hotels/${uid}/`, data)
}

export function UpdateTotem(uid, data) {
    return API.patch(`p/totems/${uid}/`, data)
}

export function GetResume() {
    return API(`p/resume/`)
}
