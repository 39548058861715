import React from 'react'
import styled from 'styled-components'
import { Row, Col, Container } from 'reactstrap'
import { GetHotel, UpdateHotel } from '../../API/Core'
import Toast from '../../Components/Toast'
import { Spinner, Switch } from '@blueprintjs/core'
import InputMask from 'react-input-mask'

export default function Detail(props) {
    const [data, setData] = React.useState(null)
    const [loading, setLoading] = React.useState(false)
    const uid = props.match.params.id

    const initData = async () => {
        let response = await GetHotel(uid)
        console.log(response)
        setData(response.data)
    }

    React.useEffect(() => {
        initData()
    }, [])

    const handleSwitch = (e) => {
        console.log(e)
        let index = e.target.getAttribute('id')
        let data_ = Object.assign({}, data)
        data_[index] = e.target.checked
        setData(data_)
    }

    const handleFields = (e) => {
        let index = e.target.getAttribute('id')
        let data_ = Object.assign({}, data)
        data_[index] = e.target.value
        setData(data_)
    }

    const handleAdvancedFields = (index, value) => {
        let data_ = Object.assign({}, data)
        data_[index] = value
        setData(data_)
    }

    const handleFiles = (e) => {
        let index = e.target.getAttribute('id')
        let data_ = Object.assign({}, data)

        data_[index] = e.target.files[0]

        setData(data_)
    }
    const saveData = async (e) => {
        e.preventDefault()
        setLoading(true)
        let data_ = Object.assign({}, data)

        if (typeof data_.logo !== 'object') delete data_['logo']
        if (typeof data_.logo_alt !== 'object') delete data_['logo_alt']
        if (typeof data_.favicon !== 'object') delete data_['favicon']

        let form = new FormData()
        for (let x in data_) {
            if (data_[x] === null || data_[x] === 'null') data_[x] = ''
            form.append(x, data_[x])
        }

        try {
            await UpdateHotel(uid, form)
            initData()

            Toast('success', 'Registro actualizado exitosamente!')
        } catch (err) {
            console.log(err)
            Toast('danger', 'Ha ocurrido un error!')
        }

        setLoading(false)
    }
    console.log(data)
    if (data !== null) {
        return (
            <Container fluid>
                <DivLoading loading={loading}>
                    <form onSubmit={saveData}>
                        <Row>
                            <Col>
                                <Title>
                                    <h2>Datos del Hotel</h2>
                                </Title>
                            </Col>
                            <Col xs="12">
                                <Div>{loading && <Spinner size="16" />}</Div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6" xs="12">
                                <Label className="form-group w-100">
                                    Código del hotel
                                    <br />
                                    {data.uid}
                                </Label>
                            </Col>
                            <Col md="6" xs="12">
                                <Label className="form-group w-100">
                                    Estado <Required>*</Required>
                                    <ContentSwitch className="w-100">
                                        <Switch onChange={handleSwitch} id="is_active" checked={data.is_active} large LabelElement={'Estado'} innerLabelChecked="Activo" innerLabel="Inactivo" />
                                    </ContentSwitch>
                                </Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6" xs="12">
                                <Label className="form-group w-100">
                                    Nombre del hotel <Required>*</Required>
                                    <input required className="form-control" type="text" id="name" value={data.name === null ? '' : data.name} onChange={handleFields} />
                                </Label>
                            </Col>
                            <Col md="6" xs="12">
                                <Label className="form-group w-100">
                                    Teléfono del hotel <Required>*</Required>
                                    <InputMask className="form-control" required id={'phone'} value={data.phone} mask="+9 (999) 999 9999" onChange={handleFields} />
                                </Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6" xs="12">
                                <Label className="form-group w-100">
                                    Correo electrónico <Required>*</Required>
                                    <input required className="form-control" type="email" id="email" value={data.email === null ? '' : data.email} onChange={handleFields} />
                                </Label>
                            </Col>
                            <Col md="6" xs="12">
                                <Label className="form-group w-100">
                                    Lista de correos
                                    <textarea className="form-control" id="email_notification" value={data.email_notification === null ? '' : data.email_notification} onChange={handleFields} />
                                    <small>Esta lista de correos recibirá una notificación cuando se realice una reservación.</small>
                                </Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6" xs="12">
                                <Label className="form-group w-100">
                                    Dirección 1
                                    <textarea className="form-control" id="address1" value={data.address1 === null ? '' : data.address1} onChange={handleFields} />
                                </Label>
                            </Col>
                            <Col md="6" xs="12">
                                <Label className="form-group w-100">
                                    Direccíon 2
                                    <textarea className="form-control" id="address2" value={data.address2 === null ? '' : data.address2} onChange={handleFields} />
                                </Label>
                            </Col>
                        </Row>

                        <Row>
                            <Col md="6" xs="12">
                                <Label className="form-group w-100">
                                    Logo del hotel
                                    <br />
                                    <input type="file" accept="image/*" id="logo" onChange={handleFiles} />
                                    <br />
                                    <img className="mt-1" src={data.logo} height="90px;" alt="Debes guardar para ver la imagén" />
                                    <br />
                                </Label>
                            </Col>
                            <Col md="6" xs="12">
                                <Label className="form-group w-100" marginBottom="0">
                                    Logo alternativo del hotel
                                    <br />
                                    <input type="file" accept="image/*" id="logo_alt" onChange={handleFiles} />
                                    <br />
                                    <img className="mt-1" src={data.logo_alt} height="90px;" alt="Debes guardar para ver la imagén" />
                                    <br />
                                </Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6" xs="12">
                                <Label className="form-group w-100" marginBottom="0">
                                    Favicon
                                    <br />
                                    <input type="file" accept="image/*" id="favicon" onChange={handleFiles} />
                                    <br />
                                    <img className="mt-1" src={data.favicon} height="90px;" alt="Debes guardar para ver la imagén" />
                                    <br />
                                </Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="4" xs="12">
                                <Label className="form-group w-100">
                                    instagram
                                    <input placeholder="#" className="form-control" type="text" id="instagram" value={data.instagram === null ? '' : data.instagram} onChange={handleFields} />
                                </Label>
                            </Col>
                            <Col md="4" xs="12">
                                <Label className="form-group w-100">
                                    twitter
                                    <input placeholder="#" className="form-control" type="text" id="twitter" value={data.twitter === null ? '' : data.twitter} onChange={handleFields} />
                                </Label>
                            </Col>
                            <Col md="4" xs="12">
                                <Label className="form-group w-100">
                                    facebook
                                    <input placeholder="#" className="form-control" type="text" id="facebook" value={data.facebook === null ? '' : data.facebook} onChange={handleFields} />
                                </Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-center mt-4">
                                <ButtonCancel type="button" onClick={initData}>
                                    Cancelar
                                </ButtonCancel>
                                <ButtonSuccess type="submit">Guardar</ButtonSuccess>
                            </Col>
                        </Row>
                        {/* <Col md="6" xs="12">
                            <Label className="form-group w-100">
                                Tiempo para activar el Screen Saver
                                <input className="form-control" type="text" id="screensaver_time" value={data.screensaver_time === null ? '' : data.screensaver_time} onChange={handleFields} />
                            </Label>
                        </Col> */}
                        {/* <Row>
                        <Col md="6" xs="12">
                            <Label className="form-group w-100">
                                Color
                                <input className="form-control" type="text" id="color" value={data.color === null ? '' : data.color} onChange={handleFields} />
                            </Label>
                        </Col>
                        <Col md="6" xs="12">
                            <Label className="form-group w-100">
                                color_alt
                                <input className="form-control" type="text" id="color_alt" value={data.color_alt === null ? '' : data.color_alt} onChange={handleFields} />
                            </Label>
                        </Col>
                    </Row> */}
                        {/* <Label className="form-group w-100">
                        has_screensaver
                        <input className="form-control" type="text" id="has_screensaver" value={data.has_screensaver === null ? '' : data.has_screensaver} onChange={handleFields} />
                    </Label> */}
                    </form>
                </DivLoading>
            </Container>
        )
    } else {
        return <div></div>
    }
}

const Div = styled.div`
    width: 100%;
    position: relative;
    :hover .btn-edit {
        display: block;
    }
`

const Title = styled.div`
    position: relative;
    width: 100%;
    height: 20px;
    z-index: 0;

    h2 {
        padding-right: 10px;
        position: relative;
        float: left;
        left: 0;
        font-weight: bold;
        color: #57bc8f;
        font-size: 16px;
        background-color: #fff;
        z-index: 2;
    }
    :before {
        content: '';
        position: absolute;
        width: 100%;
        float: right;
        top: 50%;
        height: 1px;
        z-index: 1;
        background: #ccc;
    }
`

const ButtonCancel = styled.button`
    color: #666;
    border: none;
    background-color: #fff;
`
const ButtonSuccess = styled.button`
    color: #fff;
    background-color: #2bb05a;
    border: none;
    border-radius: 3px;
    padding: 4px 15px;
    margin-left: 15px;
`

const DivLoading = styled.div`
    transition: all 0.3s ease;
    width: 100%;
    opacity: ${(props) => (props.loading ? 0.6 : 1)};
    pointer-events: ${(props) => (props.loading ? 'none' : 'inherit')};
`

const ContentSwitch = styled.div`
    .bp3-control.bp3-switch.bp3-large:not(.bp3-align-right) .bp3-control-indicator {
        margin-left: -45px;
        outline: none !important;
        box-shadow: none !important;
    }
    .bp3-large {
        /* margin-bottom: 20px; */
        margin-top: 6px;
    }
`

const Required = styled.span`
    color: #f00;
`

const Label = styled.label`
    margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '10')}px;
    font-weight: bold;
`
